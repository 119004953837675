<template>
  <div id="contactForm" class="contact-form">
    <b-container id="contact-form">
      <h3 class="h3 text-center text-primary title mb-5">Contact us:</h3>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="input-group-name" label="" label-for="input-name">
          <b-form-input
            :state="validateState('input-name')"
            aria-describedby="input-name-feedback"
            data-vv-as="Name"
            id="input-name"
            name="input-name"
            placeholder="Name"
            v-model="form.youname"
            v-validate="{ required: true, min: 1 }"
          ></b-form-input>

          <b-form-invalid-feedback id="input-name-feedback">
            {{ veeErrors.first("input-name") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-company"
          label-for="input-company"
          label=""
        >
          <b-form-input
            :state="validateState('input-company')"
            aria-describedby="input-company-feedback"
            data-vv-as="Company"
            id="input-company"
            name="input-company"
            placeholder="Company*"
            v-model="form.youcompany"
            v-validate="{ required: true, min: 3 }"
          ></b-form-input>

          <b-form-invalid-feedback id="input-company-feedback">
            {{ veeErrors.first("input-company") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="row">
          <div class="col-md-6">
            <b-form-group
              id="input-group-email"
              label-for="input-email"
              label=""
            >
              <b-form-input
                :state="validateState('input-email')"
                aria-describedby="input-email-feedback"
                data-vv-as="E-mail"
                id="input-email"
                name="input-email"
                placeholder="E-mail*"
                v-model="form.youemail"
                v-validate="'required|email'"
              ></b-form-input>

              <b-form-invalid-feedback id="input-email-feedback">
                {{ veeErrors.first("input-email") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              id="input-group-phone"
              label-for="input-phone"
              label=""
            >
              <b-form-input
                :state="validateState('input-phone')"
                aria-describedby="input-phone-feedback"
                data-vv-as="Phone"
                id="input-phone"
                name="input-phone"
                placeholder="Phone"
                v-model="form.youphone"
                v-validate="'required|decimal'"
              ></b-form-input>

              <b-form-invalid-feedback id="input-phone-feedback">
                {{ veeErrors.first("input-phone") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <b-form-group id="input-group-country" label-for="input-group-country">
          <b-form-select
            :options="countries"
            :state="validateState('input-group-country')"
            aria-describedby="input-country-feedback"
            data-vv-as="Country"
            id="input-group-country"
            name="input-group-country"
            v-model="form.youcountry"
            v-validate="{ required: true }"
          ></b-form-select>

          <b-form-invalid-feedback id="input-country-feedback">
            {{ veeErrors.first("input-group-country") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-job-title"
          label-for="input-job-title"
          label=""
        >
          <b-form-input
            :state="validateState('input-job-title')"
            aria-describedby="input-job-title-feedback"
            data-vv-as="Job title"
            id="input-job-title"
            name="input-job-title"
            placeholder="Job title"
            v-model="form.youjob"
            v-validate="{ required: false, min: 3 }"
          ></b-form-input>
        </b-form-group>

        <!-- <b-form-group id="input-group-service" label="Service and support" label-for="input-group-service">
          <b-form-select
            id="input-group-service"
            name="input-group-service"
            v-model="form.youservice"
            v-validate="{ required: false }"
            :options="services"
            :state="validateState('input-group-service')"
            aria-describedby="input-service-feedback"
            data-vv-as="Service and Support"
          ></b-form-select>

          <b-form-invalid-feedback id="input-service-feedback">
            {{ veeErrors.first("input-group-service") }}
          </b-form-invalid-feedback>
        </b-form-group> -->

        <b-form-group id="input-group-reason" label-for="input-group-reason">
          <b-form-select
            :options="reasons"
            :state="validateState('input-group-reason')"
            aria-describedby="input-reason-feedback"
            data-vv-as="Reason"
            id="input-group-reason"
            name="input-group-reason"
            v-model="form.youreason"
            v-validate="{ required: true }"
          ></b-form-select>

          <b-form-invalid-feedback id="input-reson-feedback">
            {{ veeErrors.first("input-group-reson") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-message"
          label-for="input-message"
          label=""
        >
          <b-form-textarea
            :state="validateState('input-message')"
            aria-describedby="input-message-feedback"
            data-vv-as="Message"
            id="input-message"
            name="input-message"
            placeholder="Message* Please fill in your IMO number if applicable"
            rows="5"
            v-model="form.youmessage"
            v-validate="{ required: true, min: 3 }"
          ></b-form-textarea>

          <b-form-invalid-feedback id="input-message-feedback">
            {{ veeErrors.first("input-message") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-file"
          label-for="input-file"
          label-size="lg"
          label=""
        >
          <file-input
            :fileName="form.file && form.file.name"
            @onChange="fileChange"
            id="input-file"
            name="input-file"
          />

          <b-form-invalid-feedback id="input-file-feedback">
            {{ veeErrors.first("input-file") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="text-left">
          <small>* Fields required</small>

          <b-form-checkbox
            aria-describedby="input-checkbox-feedback"
            class="my-2"
            data-vv-as="Checkbox"
            id="input-checkbox"
            name="input-checkbox"
            type="checkbox"
            unchecked-value="not_accepted"
            v-model="status"
            value="accepted"
          >
            I agree to the Marflex's
            <router-link to="/privacy-policy">Privacy Policy</router-link>
          </b-form-checkbox>
          <p
            id="input-checkbox-feedback"
            style="color: #c1001f"
            v-if="invalidCheck && status == 'not_accepted'"
          >
            Privacy Policy is required
          </p>
        </div>

        <div class="row mb-0">
          <div class="offset-xl-8 col-md-6 col-xl-4">
            <vue-recaptcha
              :sitekey="recaptchaSiteKey"
              @expired="onCaptchaExpired"
              @verify="onCaptchaVerified"
              ref="recaptcha"
              size="invisible"
            ></vue-recaptcha>
          </div>
        </div>

        <div class="row mb-5">
          <div class="offset-xl-4 col-md-6 col-xl-4">
            <b-button
              @click="resetForm()"
              class="mb-4 btn-block p-3"
              variant="light"
              >CANCEL</b-button
            >
          </div>
          <div class="col-md-6 col-xl-4">
            <b-button
              :class="{ disabled: disabledButton }"
              class="mb-4 btn-block p-3"
              type="submit"
              variant="danger"
              >Send</b-button
            >
          </div>
        </div>

      </b-form>
    </b-container>

    <b-alert
      variant="success"
      :show="dismissCountDown"
      @dismiss-count-down="countDownChanged"
      >{{ this.contactInfo }}</b-alert
    >
    <b-alert
      :show="dismissCountDownError"
      @dismiss-count-down="countDownChangedError"
      variant="danger"
      >{{ this.errorInfo }}</b-alert
    >
  </div>
</template>

<script>
import axios from "@/axios";
import FileInput from "../components/FileInput.vue";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: {
    FileInput,
    VueRecaptcha,
  },
  props: ["id"],
  name: "ContactForm",
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      recaptchaSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      countries: [],
      reasons: [],
      subjects: [
        { value: null, text: "Services and support" },
        { value: "General inquiries", text: "General inquiries" },
        { value: "New building inquiries", text: "New building inquiries" },
        { value: "Service and support", text: "Service and support" },
        {
          value: "Spare parts/portable solutions",
          text: "Spare parts/portable solutions",
        },
      ],
      disabledButton: false,
      status: "not_accepted",
      invalidCheck: false,
      form: {
        youname: null,
        youcompany: null,
        youemail: null,
        youphone: null,
        youcountry: null,
        youjob: null,
        youreason: null,
        youmessage: null,
        file: null,
      },
      contactInfo: "",
      errorInfo: "",
      dismissSecs: 10,
      dismissCountDown: 0,
      dismissSecsError: 10,
      dismissCountDownError: 0,
      showDismissibleAlert: false,
    };
  },
  async created() {
    this.getPageDataBySlug();
  },
  methods: {
    getPageDataBySlug: function () {
      axios
        .get("contact-forms/" + this.id)
        .then((response) => {

          let kraje = [];
          let labels = response.data.properties.form.fields[4].labels;
          let values = response.data.properties.form.fields[4].raw_values;

          for (let i = 0; i < labels.length; i++) {
            kraje.push({ value: labels[i], text: values[i] });
          }
          kraje.shift();
          kraje.unshift({
            value: null,
            text: "Country*",
          });

          this.countries = kraje;

          let powody = [];
          let labels_reasons = response.data.properties.form.fields[6].labels;
          let values_reasons =
            response.data.properties.form.fields[6].raw_values;

          for (let i = 0; i < labels_reasons.length; i++) {
            powody.push({ value: labels_reasons[i], text: values_reasons[i] });
          }
          powody.shift();
          powody.unshift({
            value: null,
            text: "Reason*",
          });

          this.reasons = powody;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    fileChange(ev) {
      let fileTypes = ["doc", "docx", "pdf"];
      let fileType = ev.target.files[0].name.split(".").pop().toLowerCase();

      if (!fileTypes.includes(fileType)) {
        this.errorInfo = "Wrong file format, please try again";
        this.showError();
        return;
      }

      if (ev.target.files[0].size > 36700160) {
        this.errorInfo = "File size is too large. Maximum 35MB";
        this.showError();
        this.disabledButton = true;
        return;
      }

      this.hideError();
      this.disabledButton = false;
      this.form.file = ev.target.files[0];
    },
    resetForm() {
      this.form = {
        youname: null,
        youcompany: null,
        youemail: null,
        youphone: null,
        youcountry: null,
        youjob: null,
        youreason: null,
        youmessage: null,
        file: null,
      };
      this.status = "not_accepted";
      this.disabledButton = false;
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    onSubmit() {
      this.$refs.recaptcha.execute();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    countDownChangedError(dismissCountDownError) {
      this.dismissCountDownError = dismissCountDownError;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showError() {
      this.dismissCountDownError = this.dismissSecsError;
    },
    hideError() {
      this.dismissCountDownError = 0;
    },
    onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset();

      this.$validator.validateAll().then((result) => {
        if (!result || this.status == "not_accepted") {
          this.invalidCheck = true;
          return;
        }
        this.invalidCheck = false;
        const formData = new FormData();
        formData.append("youname", this.form.youname);
        formData.append("youcompany", this.form.youcompany);
        formData.append("youemail", this.form.youemail);
        formData.append("youphone", this.form.youphone);
        formData.append("youcountry", this.form.youcountry);
        formData.append("youjob", this.form.youjob);
        formData.append("youreason", this.form.youreason);
        formData.append("youmessage", this.form.youmessage);
        formData.append("file", this.form.file);
        formData.append("_wpcf7_recaptcha_response", recaptchaToken);

        axios({
          url: "contact-forms/" + this.id + "/feedback",
          header: { "Content-Type": "multipart/form-data" },
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            if (resp.data.status == "validation_failed") {
              this.errorInfo = resp.data.message;
              this.showError();
            } else {
              this.contactInfo = resp.data.message;
              this.showAlert();
              this.resetForm();
              this.disabledButton = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.errorInfo = err.data.message;
            this.showError();
          });
      });

    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/config";
#contact-form {
  scroll-margin-top: 70px;
}
.contact-form {
  .title {
  }
  .btn {
    text-transform: uppercase;
    font-size: 0.88rem;
    &-light {
      color: $gray-600;
    }
  }
}
.disabled {
  pointer-events: none;
  background-color: $gray-100 !important;
  border: none;
  color: #aa939e !important;
}
</style>
<style>
.grecaptcha-badge {
  z-index: 10;
}
</style>
