<template>
  <div class="contact text-white" v-if="visible">
    <div
      class="phone-container d-flex flex-row-reverse mb-1"
      @click="phoneVisible = !phoneVisible"
      v-if="pageData && pageData.acf && pageData.acf.common_company_phone"
    >
      <div class="contact-icon gray m-0 phone-icon">
        <img src="/images/icons/common/call.svg" class="img-fluid" />
      </div>
      <a
        :href="'tel:' + pageData.acf.common_company_phone"
        class="px-4 gray align-items-center phone-number"
        :class="phoneVisible && 'phone-number--active'"
        v-text="pageData.acf.common_company_phone"
      >
      </a>
    </div>
    <a
      href="/contact#contact-form"
      class="contact-icon bg-primary mb-1"
    >
      <img src="/images/icons/common/email.svg" class="img-fluid" />
    </a>
    <a
      class="contact-icon bg-primary flex-column mb-1"
      @click="scrollToTop"
      v-if="topVisible"
    >
      <img
        src="/images/icons/common/card-arrow.svg"
        class="img-fluid"
        style="transform: rotate(180deg)"
      />
      <span class="top-text text-white">top</span>
    </a>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      pageData: [],
      phoneVisible: false,
      topVisible: false,
      timeout: null,
      visible: false,
    };
  },
  methods: {
    scrollToTop: function (e) {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    watchScrollPosition: function () {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.visible = true;
      this.timeout = setTimeout(() => (this.visible = false), 4000);
      if (window.pageYOffset > 0) {
        this.topVisible = true;
      } else {
        this.topVisible = false;
      }
    },
    getPageDataBySlug: function () {
      axios
        .get("marflex/configuration")
        .then((response) => {
          this.pageData = response.data[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPageDataBySlug();
    this.watchScrollPosition();
    document.addEventListener("scroll", this.watchScrollPosition);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.watchScrollPosition);
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";
.contact {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1029;
  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 67px;
    min-height: 67px;
    z-index: 999;
    cursor: pointer;
  }
}
.gray {
  background: $gray-600;
}

.top-text {
  line-height: 15px;
  font-size: 16px;
}
.phone {
  &-container {
    position: relative;
  }
  &-icon:hover + &-number {
    transform: translateX(-100%);
  }
  &-number {
    position: absolute;
    width: 270px;
    left: 0;
    transform: translateX(0);
    transition: transform 0.5s;
    display: flex;
    height: 100%;
    &:hover,
    &--active {
      transform: translateX(-100%);
    }
  }
}
</style>
