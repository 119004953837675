<template>
  <div class="share-icons my-5" v-if="!isLoading">
    <p class="text-muted text-uppercase text-center">SHARE:</p>
    <div class="d-flex justify-content-center">

      <ShareNetwork
        network="twitter"
        :url="url"
        :title="title"
        :description="description"
        v-if="pageData.share_to_twitter"
        class="mx-2"
      >
        <b-img fluid src="/images/twitter.svg" alt="" class=""></b-img>
      </ShareNetwork>

      <a :href="`mailto:?subject=` + title + `&amp;body=Check out this site ` + url" v-if="pageData.share_to_email" class="mx-2">
        <b-img fluid src="/images/mail.svg" alt="" class=""></b-img>
      </a>

      <ShareNetwork
        network="linkedin"
        :url="url"
        :title="title"
        :description="description"
        v-if="pageData.share_to_linkedin"
        class="mx-2"
      >
        <b-img fluid src="/images/linkedin.svg" alt="" class=""></b-img>
      </ShareNetwork>

      <ShareNetwork
        network="weibo"
        :url="url"
        :title="title"
        :description="description"
        v-if="pageData.share_to_weibo"
        class="mx-2"
      >
        <b-img fluid src="/images/unknown.svg" alt="" class=""></b-img>
      </ShareNetwork>

      <ShareNetwork
        network="facebook"
        :url="url"
        :title="title"
        :description="description"
        v-if="pageData.share_to_facebook"
        class="mx-2"
      >
        <b-img fluid src="/images/facebook.svg" alt="" class=""></b-img>
      </ShareNetwork>

    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  props: {
    url: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoading: true,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      pageData: [],
    };
  },
  mounted() {
    this.getPageDataBySlug();
  },
  methods: {
    getPageDataBySlug: function () {
      axios
        .get("marflex/configuration")
        .then((response) => {
          this.pageData = response.data[0].acf.section_share_content;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
