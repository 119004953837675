<template>
  <div>
    <!-- <div id="mapContainer">
      <div v-if="groupedMarkers.length > 0" class="section-map-legend">
        <div class="item">
          <div class="title text-white">Our locations:</div>
        </div>
        <div class="item">
          <ul>
            <li v-for="type in groupedMarkers" class="location">
              <div v-if="type.locations.length > 0" class="item-legend">
                <span class="icon">
                  <b-img
                    v-if="type.type.locationTypeMarker"
                    :src="type.type.locationTypeMarker"
                    fluid
                  ></b-img>
                </span>
                <span class="label">{{ type.type.name }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="section-map-container">
        <GmapMap
          :center="{ lat: 10, lng: 10 }"
          :zoom="zoom"
          map-type-id="roadmap"
          style="width: 100%; height: 650px"
          ref="mapRef"
          :options="options"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          ></gmap-info-window>
          <gmap-marker
            v-for="(m, i) in markers"
            :position="m.position"
            :clickable="true"
            :icon="m.icon"
            @click="toggleInfoWindow(m, i)"
          ></gmap-marker>
              close @click="center = m.position"
              close @click="toggleInfoWindow(m, i)"
        </GmapMap>
      </div>
    </div> -->

    <b-container fluid="xl" class="px-0 py-7">
      <div id="locations">
        <div v-if="groupedMarkers.length > 0" class="locations">
          <div v-for="type in groupedMarkers" class="locations-type">
            <!-- Custom view based on locations type (if show by amount, change it to type.locations.length === 1) -->
            <!-- HQ -->
            <div
              class="location-items location-type-hq px-3"
              v-if="type.type.slug === 'hq' && type.locations.length > 0"
            >
              <div v-for="location in type.locations">
                <div class="location-item">
                  <h3 class="h3 text-center text-primary">
                    {{ location.data.locationTitle }}
                  </h3>
                  <div class="row card-image px-0">
                    <div class="col">
                      <b-img
                        v-if="location.data.image"
                        :src="location.data.image"
                        fluid
                      ></b-img>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-xl-4 title">
                      <h4 class="title">{{ location.data.locationName }}</h4>
                    </div>
                    <div
                      class="col-md-6 col-xl-4 px-4"
                      v-html="location.data.street"
                    ></div>
                    <div
                      class="col-md-6 col-xl-4 px-4"
                      v-html="location.data.phone"
                    ></div>
                  </div>
                  <!--                  <b-button variant="primary" v-scroll-to="'#mapContainer'">zobacz</b-button>-->
                </div>
              </div>
            </div>

            <!-- Offices -->
            <div
              class="location-items location-type-office px-3"
              v-else-if="
                type.type.slug === 'office' && type.locations.length > 0
              "
            >
              <h3 class="h3 text-center text-primary">{{ type.type.name }}</h3>
              <div class="row">
                <div class="col-md-6" v-for="location in type.locations">
                  <b-card no-body class="mb-1 border-0">
                    <b-card-body class="p-0">
                      <div class="card-image p-0">
                        <b-img
                          v-if="location.data.image"
                          :src="location.data.image"
                          fluid
                        ></b-img>
                      </div>
                      <b-card-text>
                        <h4 class="title">{{ location.data.locationTitle }}</h4>
                        <p v-html="location.data.street"></p>
                        <p v-html="location.data.phone"></p>
                      </b-card-text>
                      <!--                      <b-button variant="primary" v-scroll-to="'#mapContainer'">zobacz</b-button>-->
                    </b-card-body>
                  </b-card>
                </div>
              </div>
            </div>

            <!-- Service -->
            <!-- Sales -->
            <!-- Training -->
            <div
              class="accordion location-items location-type-other"
              role="tablist"
              v-else-if="type.locations.length > 0"
            >
              <h3 class="h3 text-center text-primary">{{ type.type.name }}</h3>
              <b-card
                bg-variant="primary"
                text-variant="white"
                no-body
                v-for="(location, index) in type.locations"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle="'accordion-' + location.data.id"
                    variant="primary"
                    class="pl-4"
                  >
                    <div class="title text-center">
                      <span class="location-name">{{
                        location.data.locationName
                      }}</span>
                      ,
                      <span class="location-title">{{
                        location.data.locationTitle
                      }}</span>
                    </div>
                    <i
                      class="ico-arrow"
                      v-bind:class="{ rotate: rotatedArrow }"
                      aria-hidden="true"
                    ></i>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :id="'accordion-' + location.data.id"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body variant="primary" class="p-0">
                    <div class="card-image">
                      <b-img
                        v-if="location.data.image"
                        :src="location.data.image"
                        fluid
                      ></b-img>
                    </div>

                    <b-card-text>
                      <div class="title text-center">
                        <span class="location-name">{{
                          location.data.locationName
                        }}</span>
                        ,
                        <span class="location-title">{{
                          location.data.locationTitle
                        }}</span>
                      </div>
                      <div class="contact">
                        <p class="item" v-html="location.data.street"></p>
                        <p class="item" v-html="location.data.phone"></p>
                      </div>
                    </b-card-text>
                    <a
                      href="#"
                      class="btn btn-primary btn-block text-left btn-marker"
                      @click="gotoMarker(location, index)"
                    >
                      <!--                      @click="gotoMarker(location, index)"-->
                      <!--                      @click="toggleInfoWindow(location.markers, index)"-->
                      <img :src="location.data.addressIcon" class="marker" />
                      <div class="label">see the location on the map</div>
                    </a>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  data() {
    return {
      backendResourceDomain: "",
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      dataFetched: false,
      dataLocationFetched: false,
      markers: null,
      groupedLocations: null,
      locationTypes: null,
      markersTest: null,
      locationTypesTest: null,
      center: { lat: 44.464, lng: 8.862 },
      place: null,
      rotatedArrow: false,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      places: [],
      groupedMarkers: [],
      zoom: 2.9,
      options: {
        minZoom: 2,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
        // gestureHandling: 'none',
        // mapId: process.env.VUE_APP_GOOGLE_MAP_ID,
        mapId: 123456789
      },
    };
  },
  async mounted() {
    this.fetchAllData();
    // this.geolocate();
    this.$refs.mapRef.$mapPromise.then((map) => {
      map.panTo({ lat: 41.8, lng: 9.4 });
    });
  },

  methods: {
    async fetchAllData() {
      const locationTypesRequest = this.axios.get(
        this.backendUrl + "map/location_types"
      );
      const locationsRequest = this.axios.get(
        this.backendUrl + "map/locations"
      );

      let groupedMarkers = [];
      await this.axios.all([locationTypesRequest, locationsRequest]).then(
        this.axios.spread((resLocationTypes, resLocations) => {
          var vm = this;
          this.locationTypes = resLocationTypes.data;
          this.locationTypes.forEach(function (entry) {
            entry.typeImageSrc = entry.acf.marker_icon.url;
            entry.typeName = entry.name;
            groupedMarkers.push({
              type: {
                id: entry.term_id,
                name: entry.name,
                slug: entry.slug,
                locationTypeMarker:
                  vm.backendResourceDomain +
                  entry.acf.marker_icon.url,
              },
              locations: [],
            });
          });
          this.groupedMarkers = groupedMarkers;
          this.markers = resLocations.data.map((x) => x.acf);
          this.markers.forEach(function (entry) {
            resLocationTypes.data.forEach(function (type) {
              if (type.term_id == entry.type) {
                entry.icon =
                  vm.backendResourceDomain + type.acf.marker_icon.url;
                entry.addressIcon =
                  vm.backendResourceDomain +
                  type.acf.address_icon.url;
                entry.typeName = type.name;
              }
            });
            entry.image = entry.image
              ? vm.backendResourceDomain + entry.image
              : null;
            entry.infoText =
              "<h4 style='color: black; font-size: 0.9rem; margin-bottom: 0;'>" +
              entry.location_name +
              ", " +
              entry.location_title +
              "</h4>";
            entry.infoDescription = entry.location_name;
            entry.position = {
              lat: entry.addres.lat,
              lng: entry.addres.lng,
            };
          });

          this.groupedMarkers.forEach(function (entry) {
            resLocations.data.forEach(function (location) {
              if (location.acf.type == entry.type.id) {
                // entry.locations.push({ ulica: location.acf.location_name });
                entry.locations.push({
                  title: location.post_title,
                  data: {
                    id: location.ID,
                    locationName: location.acf.location_name,
                    locationTitle: location.acf.location_title,
                    typeName: location.acf.typeName,
                    street: location.acf.full_address_section,
                    phone: location.acf.full_phone_section,
                    icon: location.acf.icon,
                    addressIcon: location.acf.addressIcon,
                    image: location.acf.image ? location.acf.image : null,
                    website: location.acf.website,
                  },
                  markers: {
                    lat: location.acf.position.lat,
                    lng: location.acf.position.lng,
                  },
                });
              }
            });
          });
        })
      );
    },
    setPlace(place) {
      this.place = place;
    },
    setDescription(infoDescription) {
      this.description = infoDescription;
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(marker.position);
      });
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
        for (var i = 1; i < 3; i++) {
          console.log("iteracja: " + i);
        }
        console.log("Marker location: " + marker.location_title);
      }
    },
    gotoMarker(location, idx) {
      this.infoWindowPos = location.markers;
      this.infoOptions.content = location.data.locationName;
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(location.markers);
      });
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
    },
    usePlace() {
      if (this.place) {
        this.markers.push({
          position: {
            lat: this.place.geometry.location.lat(),
            lng: this.place.geometry.location.lng(),
          },
        });
        this.place = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mapContainer {
  position: relative;
  height: 650px;
  .section-map-container {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .section-map-legend {
    position: absolute;
    z-index: 4;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    max-width: 100%;
    width: 250px;
    bottom: 5%;
    right: calc(50% - 125px);
    align-items: center;
    .item {
      flex: 0 0 50%;
      width: 50%;
      max-width: 150px;
      font-size: 1rem;
      ul {
        margin: 0;
        padding-left: 0;
        list-style: none;
      }
      .location {
        // background-color: #0c2552;
        background-color: #1f50a9;
        margin: 1px 0;
        .item-legend {
          height: 55px;
          display: flex;
          align-items: center;
          padding: 0 16px;
          .icon {
            margin-right: 10px;
          }
          .label {
            color: #ffffff;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .section-map-legend {
      flex-direction: column;
      max-width: 270px;
      right: 4%;
      .item {
        max-width: 122px;
        width: 100%;
        position: relative;
        .location {
          max-width: 177px;
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .section-map-legend {
      .item {
        max-width: 100%;
        position: relative;
        font-size: 1.6rem;
      }
    }
  }
}

/* Cards */
.xs-px-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.container-xl {
  padding-right: 0;
  padding-left: 0;
}
@media only screen and (min-width: 768px) {
  .container-xl {
    padding-right: 15px;
    padding-left: 15px;
  }
  .xs-px-15 {
    padding-left: 0;
    padding-right: 0;
  }
}
#locations {
  .ico-arrow {
    width: 24px;
    height: 24px;
  }
  .ico-arrow:before {
    content: "";
    display: inline-block;
    background-image: url("/images/icons/common/card-arrow.svg");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    transition: transform 0.2s linear;
  }
  .locations-type {
    margin-top: 48px;
    .h3 {
      margin: 24px 0;
    }
  }
  .accordion {
    .card {
      margin: 1px 0;
    }
  }
  .card-header {
    .btn {
      text-align: left;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .ico-arrow {
        align-self: center;
      }
      &.not-collapsed {
        .ico-arrow {
          &:before {
            transform: rotateZ(180deg);
          }
        }
      }
    }
    .location-name {
      text-transform: uppercase;
    }
  }
  .card-image {
    margin-bottom: 54px;
    padding: 0 15px;
  }
  .card-text {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    .title {
      text-align: left;
      font-size: 2.25rem;
      margin-bottom: 58px;
    }
    .contact {
      align-self: center;
      display: flex;
      flex-direction: column;
      .item {
        padding: 15px;
      }
    }
  }

  .btn-marker {
    background-color: #153163;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    .marker {
      width: 60px;
      height: auto;
      align-self: center;
      margin-bottom: 15px;
    }
    .label {
      position: relative;
      align-self: center;
      display: flex;
      flex-direction: column;
      &:after {
        content: "";
        display: block;
        background-image: url("/images/icons/common/icon-right-arrow.svg");
        background-repeat: no-repeat;
        width: 28px;
        height: 28px;
        align-self: center;
        margin-top: 15px;
      }
    }
  }

  /* Google Map Infowindow */
  .info-text {
    color: #ff0000;
  }
  .gm-style-iw {
    &.gm-style-iw-c {
      background-color: #ff0000 !important;
      outline: solid 1px blue;
    }
  }
  .storemapper-iw-inner {
    color: blue !important;
    outline: solid 1px lime;
  }
  @media only screen and (min-width: 768px) {
    .accordion {
      .card-text {
        .title {
          padding: 0 15px;
        }
      }
    }
    .card-image {
      margin-bottom: 84px;
      padding: 0;
    }
    .card-header {
      .btn {
        flex-direction: row;
      }
    }
    .card-text {
      .contact {
        flex-direction: row;
        .item {
          padding: 0 50px;
        }
      }
    }
    .btn-marker {
      position: relative;
      padding: 10px 153px;
      margin-bottom: 2.25rem;
      margin-top: 100px;
      flex-direction: row;
      .marker {
        position: absolute;
        bottom: 16px;
        left: 49px;
      }
      .label {
        align-self: center;
        &:after {
          position: absolute;
          top: 6px;
          right: -63px;
          margin-top: 0;
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .card-image {
      margin-bottom: 84px;
    }
    .card-text {
      padding: 0 65px;
    }
  }
}
</style>
