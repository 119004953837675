var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main"},[(_vm.parentPageData && _vm.parentPageData.acf && _vm.parentPageData.acf.show_banner)?_c('page-slider',{attrs:{"slides":_vm.parentPageData.acf.banner_group.slides}}):_c('div',{staticClass:"py-4"}),_c('b-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasData),expression:"hasData"}],attrs:{"fluid":"xl"}},[_c('div',{staticClass:"meta d-flex my-5 align-items-start"},[_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.pageData.post_date))]),_c('badges',{attrs:{"categories":_vm.pageData.categories,"markets":_vm.pageData.markets}})],1),_c('b-row',[(_vm.pageData.acf)?_c('b-col',{staticClass:"col-12 col-sm-12 order-first",class:_vm.pageData.acf.related_items ? 'col-xl-8' : 'col-xl-12'},[_c('section-title',{staticClass:"mb-5 text-left"},[_vm._v(_vm._s(_vm.pageData.title))])],1):_vm._e(),(_vm.pageData.acf && _vm.pageData.acf.related_items)?_c('b-col',{staticClass:"col-related order-2 col-lg-4 heading",attrs:{"xl":"4"}},[(
            _vm.hasParentData &&
            _vm.parentPageData.acf.section_2 &&
            _vm.parentPageData.acf.section_2.label
          )?_c('h3',{staticClass:"h3 text-center mt-2",domProps:{"textContent":_vm._s(_vm.parentPageData.acf.section_2.label)}}):_vm._e()]):_vm._e(),_c('b-col',{staticClass:"col-12 col-sm-12 order-3 article",class:_vm.pageData.acf.related_items ? 'col-xl-8' : 'col-xl-12'},[_c('divider'),_c('div',{staticClass:"excerpt my-5 text-bold text-small",domProps:{"innerHTML":_vm._s(_vm.pageData.acf.description_intro)}}),_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.pageData.acf.additional_image_top)?_c('img',{staticClass:"img-fluid mb-4",attrs:{"src":_vm.backendResourceDomain +
              _vm.pageData.acf.additional_image_top.sizes.event,"alt":""}}):_vm._e()]),_c('div',{staticClass:"text text-small mb-4",domProps:{"innerHTML":_vm._s(_vm.pageData.acf.description)}}),_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.pageData.acf.additional_image)?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.backendResourceDomain +
              _vm.pageData.acf.additional_image.sizes.event,"alt":""}}):_vm._e()]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-12 col-md-6 d-flex flex-wrap justify-content-center mt-auto"},[(_vm.pageData.acf.files)?_c('product-wave',{staticClass:"my-5",attrs:{"attachments":_vm.pageData.acf.files}}):_vm._e()],1)]),(!_vm.isLoading && _vm.pageData.url && _vm.pageData.acf.section_seo)?_c('socials',{staticClass:"d-xl-none",attrs:{"description":_vm.pageData.acf.section_seo.meta_description,"title":_vm.pageData.acf.section_seo.meta_title,"url":_vm.pageData.url}}):_vm._e()],1),(_vm.pageData.acf && _vm.pageData.acf.related_items)?_c('b-col',{staticClass:"col-related news-swiper order-4 col-lg-4",attrs:{"xl":"4"}},[_c('related-news-swiper',{attrs:{"slides":_vm.pageData.acf.related_items,"endpoint":this.$route.meta.slug}})],1):_vm._e()],1),_c('b-container',{staticClass:"d-none d-xl-block",attrs:{"fluid":"xl"}},[(!_vm.isLoading && _vm.pageData.url && _vm.pageData.acf.section_seo)?_c('socials',{attrs:{"title":_vm.pageData.acf.section_seo.meta_title,"url":_vm.pageData.url,"description":_vm.pageData.acf.section_seo.meta_description}}):_vm._e()],1),(
      _vm.hasParentData &&
      _vm.parentPageData.acf.section_2 &&
      _vm.parentPageData.acf.section_2.back_button &&
      _vm.parentPageData.acf.section_2.back_button.url &&
      _vm.parentPageData.acf.section_2.back_button.anchor
    )?_c('router-link',{staticClass:"btn btn-primary p-4 text-small text-uppercase w-100 text-bold redirect-btn my-5",attrs:{"to":_vm.parentPageData.acf.section_2.back_button.url}},[_c('span',[_vm._v(_vm._s(_vm.parentPageData.acf.section_2.back_button.anchor))])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }