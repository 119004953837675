<template>
  <label
    :for="id"
    class="custom-file__input w-100 d-flex align-items-center p-4 bg-white"
    :class="!fileName && 'justify-content-end'"
  >
    <input
      type="file"
      :name="name"
      :id="id"
      accept=".doc, .docx, .pdf"
      class="invisible position-absolute"
      @change="handleChange"
    />
    <p v-if="fileName" class="custom-file__input--text m-0 text-bold text-left">
      {{ fileName }}
    </p>
    <div class="ml-auto d-flex align-items-center">
      <p class="custom-file__input--text m-0 text-bold mr-4">
        Click to add file
      </p>
      <img src="/images/icons/common/icon-right-arrow-black.svg" />
    </div>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: false,
    },
  },
  methods: {
    handleChange(ev) {
      this.$emit("onChange", ev);
    },
  },
};
</script>

<style scoped lang="scss">
.custom-file__input {
  background: transparent;
  height: 80px;
  border: 1px solid #ced4da;
  &--text {
    font-size: 14px;
  }
}
</style>
