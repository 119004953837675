<template>
  <div class="divider w-100" />
</template>

<script>
export default {};
</script>

<style scoped>
.divider {
  height: 1px;
  background: black;
}
</style>
