<template>
  <div class="d-flex flex-wrap swiper justify-content-center mx-auto">
    <button
      :style="[arrowsVisibility ? {'display': 'block'} : {'visibility': 'hidden'}]"
      class="btn swiper-button-prev--custom col-3 col-md-1 order-2 order-md-1"
      slot="button-prev"
      @click="$refs.swiper.$swiper.slidePrev()"
    >
        <img
        :src="white ? 'images/icons/common/swiper-arrow-white.svg' : '/images/icons/common/arrow.svg'"
        alt=""
        class="icon"
      />
    </button>
    <swiper
      ref="swiper"
      :options="swiperOptions"
      class="col-12 col-md-10 order-md-2 m-0"
      slides-per-view="5"
      space-between="50"
    >
      <!--      slides-per-view="5"-->
      <!--      space-between="50"-->
      <slot />
    </swiper>
    <div
      class="col-6 order-3 text-small text-bold text-center justify-content-center align-items-center d-md-none pt-3"
      :class="arrowsLabelVisibility ? 'd-inline-block' : 'd-none'"
    >
      Previous / Next
    </div>
      <button
      :style="[arrowsVisibility ? {'display': 'block'} : {'visibility': 'hidden'}]"
      class="btn swiper-button-next--custom col-3 col-md-1 order-4 order-md-3 mt-1 mt-md-0"
      slot="button-next"
      @click="$refs.swiper.$swiper.slideNext()"
    >
        <img
        :src="white ? 'images/icons/common/swiper-arrow-white.svg' : '/images/icons/common/arrow.svg'"
        alt=""
        class="icon"
      />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    breakpoints: {
      type: Object,
      required: false,
    },
    white: {
      type: Boolean,
      required: false,
    },
    arrowsVisibility:{
      type: Boolean,
      required: false,
    },
    arrowsLabelVisibility:{
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 20,
        breakpoints: this.breakpoints,
        navigation: {
          nextEl: ".swiper-button-nexts",
          prevEl: ".swiper-button-prevs",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  max-width: 1500px;
  height: auto;
}
.swiper-button-next--custom {
  transform: rotate(180deg);
}
.icon {
  height: 24px;
}
button:focus{
  outline: none;
  border: none;
}
</style>
