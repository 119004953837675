<template>
  <div class="swiper">
    <button
      class="btn btn-block swiper-button-prev--custom"
      slot="button-prev"
      @click="$refs.swiper.$swiper.slidePrev()"
    >
      <img
        src="/images/icons/common/icon-top-arrow-blue.svg"
        alt=""
        class="icon"
      />
    </button>
    <divider class="bg-info mb-4" />
    <swiper ref="swiper" :options="swiperOptions" class="m-1">
      <slot />
    </swiper>
    <divider class="bg-info mt-4" />
    <button
      class="btn btn-block swiper-button-next--custom mt-3"
      slot="button-next"
      @click="$refs.swiper.$swiper.slideNext()"
    >
      <img
        src="/images/icons/common/icon-top-arrow-blue.svg"
        alt=""
        class="icon"
      />
    </button>
  </div>
</template>

<script>
import Divider from "./Divider";

export default {
  components: {
    Divider,
  },
  props: {
    breakpoints: {
      type: Object,
      required: false,
    },
    slidesPerView: {
      type: Number,
      required: false,
    },
    white: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      swiperOptions: {
        breakpoints: this.breakpoints,
        slidesPerView: 2,
        navigation: {
          nextEl: ".swiper-button-nexts",
          prevEl: ".swiper-button-prevs",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  &-container {
    height: 600px;
  }
  @media (min-width: 768px) {
    &-container {
      height: 1600px;
    }
  }
}

.swiper-button-next--custom {
  transform: rotate(180deg);
}
.icon {
  height: 24px;
}
</style>
