var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main"},[(_vm.pageData && _vm.pageData.acf && _vm.pageData.acf.show_banner)?_c('page-slider',{attrs:{"slides":_vm.pageData.acf.banner_group.slides}}):_c('div',{staticClass:"py-4"}),(_vm.pageData.acf)?_c('b-container',{attrs:{"fluid":"xl"}},[_c('section-title',{staticClass:"my-5"},[_vm._v(_vm._s(_vm.pageData.acf.title))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.pageData.acf.text)}}),_c('h3',{staticClass:"text-primary text-center my-5"},[_vm._v(" Choose the market of interest ")]),_c('div',{staticClass:"row"},[_vm._l((_vm.marketTabs),function(tab,index){return _c('div',{key:tab.slug,staticClass:"col-12 col-md-4 my-3 my-md-0 market-tab",class:_vm.activeTab === index
            ? `text-white market-active--${tab.slug}`
            : `text--${tab.slug}`,on:{"click":function($event){_vm.activeTab = index;
          _vm.market_category = tab.slug;
          _vm.getProducts();}}},[_c('div',{staticClass:"position-relative overflow-hidden"},[_c('img',{style:([
              _vm.activeTab === index ? { width: '101%' } : { width: '100%' },
            ]),attrs:{"src":_vm.activeTab === index
                ? `/images/tab_market_${tab.slug}.svg`
                : '/images/tab_market_transparent.svg'}}),(tab.title)?_c('h3',{staticClass:"market-tab__text text-bold text-uppercase"},[_vm._v(_vm._s(tab.title))]):_vm._e()]),_c('div',{staticClass:"d-md-none",class:_vm.activeTab === index
              ? `d-block market-tab--${_vm.marketTabs[_vm.activeTab].slug} text-small p-2`
              : 'd-none',domProps:{"innerHTML":_vm._s(tab.acf.description)}})])}),(_vm.chosenMarket)?_c('div',{staticClass:"market-tab__content p-4 text-white text-small",class:`market-tab--${_vm.marketTabs[_vm.activeTab].slug} d-none d-md-block`},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.marketTabs[_vm.activeTab].acf.description)}})]):_vm._e()],2),(_vm.chosenMarket)?_c('h5',{staticClass:"text-center my-5",class:`text--${_vm.marketTabs[_vm.activeTab].slug}`},[_vm._v(" Dedicated products ")]):_vm._e(),_c('dedicated-product',{directives:[{name:"show",rawName:"v-show",value:(_vm.productsList),expression:"productsList"}],staticClass:"mt-5",attrs:{"products":_vm.productsList}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }