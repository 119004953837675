import Vue from "vue";
import VueRouter from "vue-router";
import Contact from "../views/Contact.vue";
import Page404 from "../views/Page404.vue";
import Home from "../views/Home.vue";
import Post from "../views/Post.vue";
import TestComponent from "../views/TestContact.vue";
import Download from "../views/Download.vue";
import Products from "../views/Products.vue";
import Markets from "../views/Markets.vue";
import SingleProduct from "../views/SingleProduct.vue";
import SingleNews from "../views/SingleNews.vue";
import DefaultTextTemplate from "../views/DefaultTextTemplate.vue";


Vue.use(VueRouter);

const routes = [
  // The route for "/?preview=true"
  {
    path: "/",
    name: "PreviewHome",
    component: SingleNews, // Set the component you want to render for the preview route
    beforeEnter: (to, from, next) => {
      const isPreview = to.query.preview === "true";
      if (isPreview) {
        // Use YourPreviewComponent for the preview route
        next();
      } else {
        // Use Home component for the regular route
        next({ name: "Home" });
      }
    },
    meta: {
      endpoint: 'blog-articles',
      slug: 'article',
      parent_page_slug: 'blog',
    }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/test-component",
    name: "TestComponent",
    component: TestComponent,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },

  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/News.vue"),
    meta: {
      category_taxonomy: 'article_category',
      endpoint_page: 'blog',
      slug_page: 'blog',
      endpoint_items: 'blog-articles',
      slug_item: 'article',
    }
  },
  {
    path: "/article/:id",
    name: "blog",
    component: SingleNews,
    meta: {
      endpoint: 'blog-articles',
      slug: 'article',
      parent_page_slug: 'blog',
    }
  },

  {
    path: "/case-studies",
    name: "Case Studies",
    component: () => import("../views/CaseStudies.vue"),
  },
  {
    path: "/case_study/:id",
    name: "Single Case Study",
    component: SingleNews,
    meta: {
      endpoint: 'case-studies',
      slug: 'case-studies',
      parent_page_slug: 'case-studies',
    }
  },

  {
    path: "/auxiliary-equipment",
    name: "Auxiliary Equipment",
    component: () => import("../views/Equipment.vue"),
  },
  {
    path: "/auxiliary-equipment/:id",
    name: "Single Auxiliary Equipment",
    component: SingleNews,
    meta: {
      endpoint: 'auxiliary-equipment',
      slug: 'auxiliary-equipment',
      parent_page_slug: 'auxiliary-equipment',
    }
  },

  {
    path: "/spare-parts",
    name: "Spare parts",
    component: () => import("../views/SpareParts.vue"),
  },
  {
    path: "/spare_part/:id",
    name: "Single Spare Part",
    component: SingleNews,
    meta: {
      endpoint: 'spare-parts',
      slug: 'spare-parts',
      parent_page_slug: 'spare-parts',
    }
  },

  /* Pages based on news template (list items and single item) */
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
    meta: {
      category_taxonomy: 'news_category',
      endpoint_page: 'news',
      slug_page: 'news',
      endpoint_items: 'news',
      slug_item: 'news',
    }

  },
  {
    path: "/news/:id",
    name: "news",
    component: SingleNews,
    meta: {
      endpoint: 'news',
      slug: 'news',
      parent_page_slug: 'news',
    }
  },

  {
    path: "/services",
    name: "Services",
    component: () => import("../views/News.vue"),
    meta: {
      category_taxonomy: 'service_category',
      endpoint_page: 'services',
      slug_page: 'services',
      endpoint_items: 'services',
      slug_item: 'services',
    }
  },
  {
    path: "/services/:id",
    name: "services",
    component: SingleNews,
    meta: {
      endpoint: 'services',
      slug: 'services',
      parent_page_slug: 'services',
    }
  },

  // Support - clone of SingleNews.vue - only for support
  {
    path: "/support",
    name: "support",
    component: () => import("../views/Service.vue"),
    meta: {
      endpoint: 'services',
      slug: 'services',
      parent_page_slug: 'services',
    }
  },

  {
    path: "/trainings",
    name: "Trainings",
    component: () => import("../views/News.vue"),
    meta: {
      category_taxonomy: 'training_category',
      endpoint_page: 'trainings',
      slug_page: 'trainings',
      endpoint_items: 'trainings',
      slug_item: 'trainings',
    }
  },
  {
    path: "/trainings/:id",
    name: "Training",
    component: SingleNews,
    meta: {
      endpoint: 'trainings',
      slug: 'trainings',
      parent_page_slug: 'trainings',
    }
  },

  {
    path: "/rnds",
    name: "R&Ds",
    component: () => import("../views/News.vue"),
    meta: {
      category_taxonomy: 'rnd_category',
      endpoint_page: 'rnds',
      slug_page: 'rnds',
      endpoint_items: 'rnds',
      slug_item: 'rnds',
    }
  },
  {
    path: "/rnds/:id",
    name: "R&D",
    component: SingleNews,
    meta: {
      endpoint: 'rnds',
      slug: 'rnds',
      parent_page_slug: 'rnd',
    }
  },

  {
    path: "/search/:term",
    name: "Search",
    component: () => import("../views/Search.vue"),
  },

  {
    path: "/career",
    name: "Career",
    component: () => import("../views/Career.vue"),
    meta: {
      endpoint: 'career',
      slug: 'career',
    }
  },
  {
    path: "/our-values",
    name: "Our values",
    component: () => import("../views/Career.vue"),
    meta: {
      endpoint: 'our-values',
      slug: 'our-values',
    }
  },
  {
    path: "/why-marflex",
    name: "Why Marflex",
    component: () => import("../views/Career.vue"),
    meta: {
      endpoint: 'why-marflex',
      slug: 'why-marflex',
    }
  },

  {
    path: "/employee-stories",
    name: "Employee stories",
    component: () => import("../views/News.vue"),
    meta: {
      category_taxonomy: 'employee_category',
      endpoint_page: 'employee-stories',
      slug_page: 'employee-stories',
      endpoint_items: 'employee-stories',
      slug_item: 'employee_story',
    }
  },
  {
    path: "/employee_story/:id",
    name: "Employee Story",
    // component: () => import("../views/SingleEmployee.vue"),
    component: SingleNews,
    meta: {
      endpoint: 'employee-stories',
      slug: 'employee_story',
      parent_page_slug: 'employee-stories',
    }
  },

  {
    path: "/service",
    name: "service",
    component: () => import("../views/Support.vue"),
  },
  {
    path: "/product/:id",
    name: "Single Product",
    component: SingleProduct,
  },
  {
    path: "/article/:slug",
    name: "Post",
    component: Post,
    props: true,
  },
  {
    path: "/download",
    name: "Download Hub",
    component: Download,
    props: true,
  },
  {
    path: "/pump-solutions",
    name: "Pump solutions",
    component: Products,
  },
  {
    path: "/markets/:slug/:tab",
    name: "Markets",
    component: Markets,
  },
  {
    path: "/markets",
    name: "Markets",
    component: Markets,
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: DefaultTextTemplate,
    meta: {
      endpoint: 'privacy-policy',
      slug: 'privacy-policy',
    }
  },
  {
    path: "/terms-of-service",
    name: "Terms",
    component: DefaultTextTemplate,
    meta: {
      endpoint: 'terms-of-service',
      slug: 'terms-of-service',
    }
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: DefaultTextTemplate,
    meta: {
      endpoint: 'cookies',
      slug: 'cookies',
    }
  },
  {
    path: "*",
    component: Page404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
});

export default router;
