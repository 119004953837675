var render = function render(){var _vm=this,_c=_vm._self._c;return _c('swiper',{staticClass:"my-5",attrs:{"arrowsVisibility":_vm.arrowsVisibility,"breakpoints":_vm.breakpoints,"arrowsLabelVisibility":true,"id":"news-slide"}},_vm._l((_vm.newsList),function(slide){return _c('swiper-slide',{key:slide.title,staticClass:"related-items-swiper d-flex justify-content-center flex-wrap news-slide"},[_c('div',{staticClass:"news-thumbnail d-flex justify-content-center align-items-end mb-3",style:([
        slide.acf.short_description_image &&
        slide.acf.short_description_image.sizes &&
        slide.acf.short_description_image.sizes.square_large
          ? {
              background:
                'url(' +
                _vm.backendResourceDomain +
                slide.acf.short_description_image.sizes.square_large +
                ')',
                'background-size': 'cover',
            }
          : { background: '#e5e5e5' },
      ])},[_c('router-link',{staticClass:"btn text-primary bg-white p-4 text-small text-uppercase w-75 text-bold news-read__btn",attrs:{"to":'news/' + slide.id}},[_vm._v(" read more ")])],1),_c('div',{staticClass:"d-flex justify-content-center align-items-center w-100 mt-2 px-2"},[_c('h6',{staticClass:"news-date text-primary text-small text-center mx-auto"},[_vm._v(" "+_vm._s(slide.post_date)+" ")]),_c('badges',{attrs:{"categories":slide.categories,"markets":slide.markets}})],1),_c('h3',{staticClass:"text-primary text-bold text-center mb-4 px-4"},[_vm._v(_vm._s(slide.title))]),_c('p',{staticClass:"text-small px-4",domProps:{"innerHTML":_vm._s(slide.acf.description_intro)}})])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }