<template>
  <div>
    <div class="container p-4 bg-primary" v-if="firstFilter">
      <b-form>
        <div class="row d-flex justify-content-center">
          <div class="col-md-6">
            <div class="text-center text-white text-uppercase py-4">
              Choose category
            </div>
            <b-form-group
              id="input-group-category"
              class="mb-0"
              label=""
              label-for="input-group-category"
            >
              <b-form-select
                v-model="selected1"
                class="mb-3 bg-primary text-white"
                @change="chooseFirstFilter(selected1)"
              >
                <b-form-select-option value="">All</b-form-select-option>
                <b-form-select-option
                  v-for="opt in firstFilter"
                  :value="opt.slug"
                  >{{ opt.name }}</b-form-select-option
                >
              </b-form-select>

              <!-- <b-form-select
                id="input-group-category"
                name="input-group-category"
                v-model="form.category"
                v-validate="{ required: false }"
                :options="firstFilter"
                :state="validateState('input-group-category')"
                aria-describedby="input-category-feedback"
                data-vv-as="Category"
                class="bg-primary text-white"
              ></b-form-select> -->

              <b-form-invalid-feedback id="input-category-feedback">
                {{ veeErrors.first("input-group-category") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-6" v-if="$route.name != 'News' && $route.name != 'Blog'">
            <div class="text-center text-white text-uppercase py-4">
              Choose market
              {{$route.name}}
            </div>
            <b-form-group
              id="input-group-market"
              class="mb-0"
              label=""
              label-for="input-group-market"
            >
              <b-form-select
                v-model="selected2"
                class="mb-3 bg-primary text-white"
                @change="chooseSecondFilter(selected2)"
              >
                <b-form-select-option value="">All</b-form-select-option>
                <b-form-select-option
                  v-for="opt in secondFilter"
                  :value="opt.slug"
                  >{{ opt.name }}</b-form-select-option
                >
              </b-form-select>

              <!-- <b-form-select
                id="input-group-market"
                name="input-group-market"
                v-model="form.market"
                v-validate="{ required: false }"
                :options="markets"
                :state="validateState('input-group-market')"
                aria-describedby="input-market-feedback"
                data-vv-as="Market"
                class="bg-primary text-white"
              ></b-form-select> -->

              <b-form-invalid-feedback id="input-market-feedback">
                {{ veeErrors.first("input-group-market") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </div>

    <divider class="my-3 d-sm-none d-none d-xl-block" />

    <b-row class="pb-5 px-3">
      <b-col xl="6" class="text-center text-md-left">
        <div v-if="allowOrderBy">
          <divider class="my-3 d-block d-xl-none" />
          <span class="label d-block d-md-inline-block">shows from</span>
          <ul class="list-inline d-inline px-2">
            <li class="list-inline-item" v-bind:class="{ active: isDesc }" @click="sortList('desc')">latest</li>
            <li class="list-inline-item" v-bind:class="{ active: isAsc }" @click="sortList('asc')">oldest</li>
          </ul>
        </div>
      </b-col>
      <b-col
        xl="6 text-right text-center text-md-left text-xl-right"
        v-if="allowPagination"
      >
        <divider class="my-3 d-block d-sm-none d-md-none" />
        <span class="label d-block d-md-inline-block">show</span>
        <ul class="list-inline d-inline px-2">
          <li class="list-inline-item" v-bind:class="{ active: is5 }" @click="perPage(5)">5</li>
          <li class="list-inline-item" v-bind:class="{ active: is10 }" @click="perPage(10)">10</li>
          <li class="list-inline-item" v-bind:class="{ active: is20 }" @click="perPage(20)">20</li>
        </ul>
        <span class="label d-block d-md-inline-block">on a page</span>
        <divider class="my-3 d-block d-sm-none d-md-none" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Divider from "../components/Divider";

export default {
  components: {
    Divider,
  },
  props: {
    firstFilter: {
      required: false,
    },
    secondFilter: {
      required: false,
    },
    listLength: {
      required: false,
    },
    allowOrderBy: {
      type: Boolean,
      required: true,
    },
    allowPagination: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isAsc: false,
      isDesc: true,
      is5: true,
      is10: false,
      is20: false,
      selected1: "",
      selected2: "",
      categories: [
        { value: null, text: "all" },
        { value: "news", text: "only news" },
        { value: "events", text: "only events" },
      ],
      markets: [
        { value: null, text: "all" },
        { value: 1, text: "maritime" },
        { value: 2, text: "offshore" },
        { value: 3, text: "industrial" },
      ],
      form: {
        category: null,
        market: null,
      },
      status: false,
    };
  },
  methods: {
    chooseFirstFilter(item) {
      this.$emit("chooseFirstFilter", item);
    },
    chooseSecondFilter(item) {
      this.$emit("chooseSecondFilter", item);
    },
    sortList(item) {
      if(item == 'asc') {
        this.isAsc = true;
        this.isDesc = false;
      } else {
        this.isAsc = false;
        this.isDesc = true;
      }
      console.log('formcat', item);
      this.$emit("sortList", item);
    },
    perPage(item) {
      if(item == 5) {
        this.is5 = true;
        this.is10 = false;
        this.is20 = false;
      } else if (item == 10) {
        this.is5 = false;
        this.is10 = true;
        this.is20 = false;
      } else {
        this.is5 = false;
        this.is10 = false;
        this.is20 = true;
      }
      this.$emit("perPage", item);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }

        alert("Form submitted!");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/config";

.list-inline-item {
  cursor: pointer;
  &:not(:last-of-type):after {
    content: " /";
  }
  &.active {
    font-weight: 700;
  }
}
</style>
