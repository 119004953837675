// utils/auth.js

// export function isUserAdmin() {
//   const cookies = document.cookie.split('; ');
//   for (const cookie of cookies) {
//     const [name, value] = cookie.split('=');
//     if (name.startsWith('wp-settings-') && value === '1') {
//       return true; // User is an admin
//     }
//   }
//   return false; // User is not an admin
// }


// utils/auth.js


export function isUserAdmin() {
  var cookie = document.cookie.indexOf('wp-settings-time') !== -1;
  console.log('cookie', cookie);

  if(cookie){
    return true;
  }

  return false;

}


