<template>
  <main class="main downloadHub">
    <page-slider
      :slides="pageDownload.acf.banner_group.slides"
      v-if="pageDownload && pageDownload.acf && pageDownload.acf.show_banner"
    ></page-slider>
    <div class="py-4" v-else></div>

    <b-container class="my-5">
      <SectionTitle>Download</SectionTitle>
      <p class="text-primary text-center px-2 description">
        In your search for PDF on the subject you are interested in please
        scroll down the page or choose the category from the list below:
      </p>
    </b-container>
    <b-container fluid="xl" class="px-0">
      <ul
        class="
          d-flex
          px-0
          align-items-center
          text-primary
          flex-wrap
          justify-content-center
        "
      >
        <!-- <template v-for="section in pageData"> -->
        <li
          class="d-flex align-items-center"
          v-for="(category, index) in pageData"
          :key="index"
          @click="scrollTo(category.title)"
          v-if="category.categories.length > 0"
        >
          {{ category.title }}
          <span
            class="listItemSeparator m-3"
            v-if="index != pageData.length - 1"
            >/</span
          >
        </li>
        <!-- </template> -->
      </ul>
      <Divider />
      <div
        v-for="(category, index) in pageData"
        :key="index"
        v-if="category.categories.length > 0"
        :id="category.title"
      >
        <h3 class="text-primary text-center categoryTitle">
          {{ category.title }}
        </h3>

        <template v-if="!category.dropdown">

          <!-- ALL POST TYPES: news / products /... -->
          <div
            v-for="posts in category.categories"
            v-if="posts.posts"
            class="post-type-items"
          >

            <!-- ARTICLES FROM THIS POST TYPE: news 1 / product 1, product 2/... -->
            <div
              v-for="items in posts.posts"
              v-if="posts.posts"
            >

              <!-- FILES IN THIS ARTICLE: news 1 / product 1, product 2/... -->
                <ListItem
                  :title="items.title"
                  :subtitle="item.title"
                  v-for="item in items.files"
                  v-if="item.title"
                >
                  <div class="d-flex justify-content-end">
                    <a
                      class="
                    text-white
                    download
                    d-md-flex
                    align-items-md-center
                    text-center
                    col-12 col-md-auto
                    py-4
                  "
                      :href="item.url"
                      v-if="item.url"
                    >
                      download
                      <span class="d-md-none">PDF</span>
                      <img
                        src="/images/icons/common/pdf.svg"
                        class="ml-2 d-none d-md-block"
                      />
                    </a>
                  </div>
                </ListItem>

            </div> <!-- ./aaaa -->

          </div> <!-- ./post-type-items -->
        </template>

        <template v-else v-for="dropdown in category.dropdown">
          <div
            class="
              dropdown
              d-flex
              justify-content-between
              align-items-center
              p-3
            "
            @click="() => setActiveDropdown(dropdown.title)"
          >
            <h5 class="text-uppercase text-primary m-0">
              {{ dropdown.title }}
            </h5>
            <div
              class="icon icon-arrow"
              :class="activeDropdown === dropdown.title && 'icon-active'"
            />
          </div>
          <template v-if="activeDropdown === dropdown.title">
            <ListItem
              :title="item.title"
              :subtitle="item.subTitle"
              v-for="(item, index) in dropdown.items"
              :key="index"
            >
              <div class="d-flex justify-content-end">
                <a
                  class="
                    text-white
                    download
                    d-md-flex
                    align-items-md-center
                    text-center
                    col-12 col-md-auto
                    py-4
                  "
                  :href="item.href"
                >
                  download
                  <span class="d-md-none">PDF</span>
                  <img
                    src="/images/icons/common/pdf.svg"
                    class="ml-2 d-none d-md-block"
                  />
                </a>
              </div>
            </ListItem>
          </template>
        </template>
      </div>

      <router-link
        class="btn btn-primary w-100 p-3 backBtn text-uppercase"
        v-if="pageDownload &&
        pageDownload.acf &&
        pageDownload.acf.section_8_active &&
        pageDownload.acf.section_8_content &&
        pageDownload.acf.section_8_content.button &&
        pageDownload.acf.section_8_content.button.url &&
        pageDownload.acf.section_8_content.button.anchor"
        :to="pageDownload.acf.section_8_content.button.url"
        v-text="pageDownload.acf.section_8_content.button.anchor"
      >
      </router-link>
    </b-container>
  </main>
</template>


<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider.vue";
import SectionTitle from "../components/SectionTitle.vue";
import ListItem from "../components/ListItem.vue";
import Divider from "../components/Divider.vue";
export default {
  data() {
    return {
      isLoading: true,
      currentPageSlug: "download",
      pageData: [],
      activeDropdown: "",
      pageDownload: [],
    };
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageDownload.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageDownload.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageDownload.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  components: {
    PageSlider,
    SectionTitle,
    ListItem,
    Divider,
  },
  async created() {
    this.getDownload();
    this.getPageDataBySlug();
  },
  methods: {
    scrollTo(title) {
      document.getElementById(title).scrollIntoView({ behavior: "smooth" });
    },
    getDownload: function () {
      axios
        .get("download-hub")
        .then((response) => {
          // console.log("download", response);
          // console.log("download headers", response.headers);
          this.pageData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPageDataBySlug() {
      axios
        .get("custom-pages/" + this.currentPageSlug)
        .then((response) => {
          // console.log("downloadpage", response);
          this.pageDownload = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setActiveDropdown: function (name) {
      if (this.activeDropdown === name) {
        this.activeDropdown = "";
      } else {
        this.activeDropdown = name;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@import "src/config";
.description {
  font-size: 16px;
  margin: 40px 0;
  font-weight: 500;
}

ul {
  list-style: none;

  li {
    font-size: 36px;
  }
}

.listItemSeparator {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.categoryTitle {
  margin: 50px 0;
}

.download {
  font-size: 16px;
  cursor: pointer;
}

.dropdown {
  border-bottom: 1px solid $blue-50;
  cursor: pointer;
}
.icon {
  min-width: 28px;
  min-height: 28px;
  background: $blue-50;
  transition: transform 0.3s;
  &-arrow {
    mask: url("/images/icons/common/card-arrow.svg");
    mask-size: cover;
    mask-repeat: no-repeat;
  }
  &-active {
    transform: rotate(180deg);
  }
}

.backBtn {
  margin: 80px 0;
  font-size: 16px;
}
</style>
