<template>
  <span class="badges" v-if="categories.length">
  <b-badge
    v-for="category in categories"
    :class="badgeClassExtraction(markets)"
  >{{ category.name }}</b-badge
  >
  </span>
</template>

<script>

export default {
  data() {
    return {};
  },
  props: {
    categories: {
      required: true,
    },
    markets: {
      required: true,
    },
  },
  methods: {
    badgeClassExtraction(item) {
      return item ? item.map(cat => 'badge-' + cat.slug).join(' ') : 'default';
    }
  },
};
</script>
<style lang="scss" scoped>
@import "src/config";

.badges {
  display: inline-block;
  vertical-align: baseline;
}
.badge {
  color: #fff;
  background-color: $gray-600;
  font-weight: 300;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-left: 10px;
  vertical-align: top;

  &-maritime {
    background-color: $blue-20;
  }
  &-offshore {
    background-color: $blue-10;
  }
  &-industrial {
    background-color: $red;
  }
}
</style>
