<template>
  <div>
    <b-row class="item">
      <b-col md="6" xl="4" class="col-12 mb-3 pt-3">
        <div class="content d-flex flex-column justify-content-between">
          <span @click="goToPage('product/' + id)">
            <section-title class="text-left pb-4 pt-0">{{
              title
            }}</section-title>
          </span>
          <div class="meta">
            <dl class="labels">
              <dd
                :class="`label label-${item.name}`"
                v-for="(item, i) in markets"
                :key="i"
              >
                {{ item.name }}
              </dd>
            </dl>
            <div v-for="category in categories" class="category">
              {{ category.name }}
            </div>
          </div>
          <div class="text-small excerpt d-none d-md-block d-xl-none pt-3">
            {{ excerpt }}
          </div>
        </div>
      </b-col>
      <b-col md="6" xl="8" class="">
        <b-row class="order-md-2">
          <b-col md="12" xl="3" class="pt-3 order-md-2"
            ><img v-if="image" :src="image" class="img-fluid" alt=""
          /></b-col>
          <b-col md="12" xl="9" class="d-md-none d-xl-block pt-3">
            <p class="text-small excerpt d-block" v-html="excerpt"></p>
          </b-col>
        </b-row>
        <b-row class="d-md-none d-xl-flex">
          <b-col>
            <router-link
              :to="'product/' + id"
              class="
                btn btn-primary
                p-3
                px-5
                mt-4
                mx-auto
                text-small text-uppercase
                btn-block
                mb-3
              "
            >
              learn more
            </router-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="d-none d-md-flex d-xl-none">
      <b-col>
        <router-link
          :to="'product/' + id"
          class="
            btn btn-primary
            p-3
            px-5
            mt-4
            mb-4
            mx-auto
            text-small text-uppercase
            btn-block
          "
        >
          see the details
        </router-link>
      </b-col>
    </b-row>
    <slot></slot>
  </div>
</template>

<script>
import SectionTitle from "./SectionTitle";

export default {
  components: { SectionTitle },
  data() {
    return {};
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    image: {
      required: false,
    },
    slug: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    excerpt: {
      type: String,
      required: false,
    },
    categories: {
      type: Array,
      required: true,
    },
    markets: {},
    marketName: {
      type: String,
    },
  },
  methods: {
    goToPage(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";
.content {
  height: 100%;
  span {
    cursor: pointer;
  }
}
.item {
  .meta {
    font-family: "NeoSansPro Medium";
    font-style: normal;
  }
  .labels {
    font-weight: 300;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 9px;

    .label {
      margin-bottom: 0;
      &-Maritime {
        color: $blue-20;
      }
      &-Offshore {
        color: $blue-10;
      }
      &-Industrial {
        color: $red;
      }
    }
  }
  .category {
    font-family: "NeoSansPro Light";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: block;
  }
  .btn {
    //max-width: 300px;
  }
}
</style>
