import Vue from "vue";
import VueCookies from "vue-cookies";
import VeeValidate from "vee-validate";
import axios from "axios";
import VueAxios from "vue-axios";
import {
  BootstrapVue,
  IconsPlugin,
  CarouselPlugin
} from "bootstrap-vue";
import * as VueGoogleMaps from "gmap-vue";
import App from "./App.vue";
import router from "./router";
import VueScrollTo from "vue-scrollto";
import VueAwesomeSwiper from "vue-awesome-swiper";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueMeta from 'vue-meta'
import VueSocialSharing from "vue-social-sharing"
import { isUserAdmin } from './utils/auth';

router.beforeEach((to, from, next) => {
  if (to.name === 'PreviewHome') {
    const isAdmin = isUserAdmin();

    console.log("IS Admin", isAdmin);

    if (isAdmin) {
      next();
    } else {
      next({ name: 'Home' });
    }
  } else {
    next();
  }
});

const backendDomain = process.env.VUE_APP_BACKEND_DOMAIN;
console.log('Backend Domain:', backendDomain)


Vue.use(VueCookies)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.component('loading-overlay', Loading)

// Customize bootstrap and app styles
import "./app.scss";
import "swiper/swiper-bundle.css";
Vue.use(VueAxios, axios);
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors",
});
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.use(VueGoogleMaps, {
  load: {
    // key: process.env.VUE_APP_GOOGLE_API_KEY,
    key: '',
    libraries: "places,drawing,visualization", // This is required if you use the Autocomplete plugin
    stylers: [{
      saturation: -100
    }],
  },
  installComponents: true,
});

axios.get('http://34.240.95.30/wp-json/wp/v2/custom-site-settings')
      .then(response => {
        const GOOGLE_API_KEY = response.data.api_key;

        Vue.use(VueGoogleMaps, {
          load: {
            // key: process.env.VUE_APP_GOOGLE_API_KEY,
            key: GOOGLE_API_KEY,
            libraries: "places,drawing,visualization", // This is required if you use the Autocomplete plugin
            stylers: [{
              saturation: -100
            }],
          },
          installComponents: true,
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(CarouselPlugin);
Vue.use(VueAwesomeSwiper);
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
