<template>
  <main class="main" v-if="hasData">
    <page-slider
      :slides="pageData.acf.banner_group.slides"
      v-if="pageData && pageData.acf && pageData.acf.show_banner"
    ></page-slider>

    <b-container fluid="xl">
      <section-title class="my-5">{{ pageData.title }}</section-title>
      <div class="mb-5">
        <h4
          :class="
            'm-0 market__subtitle text-center text-uppercase text--' + item.slug
          "
          v-for="(item, index) in pageData.markets"
        >
          {{ item.name }}
        </h4>
        <p
          class="
            text-center text-uppercase text-weight-light
            product-name
            text-small
          "
          v-for="(item, index) in pageData.categories"
        >
          {{ item.name }}
        </p>
      </div>
    </b-container>
    <swiper
      :arrowsVisibility="arrowsVisibility"
      :breakpoints="productImagesBreakpoints"
      v-if="pageData && pageData.acf && pageData.acf.product_images"
    >
      <swiper-slide
        v-for="image in pageData.acf.product_images"
        class="d-flex justify-content-center"
      >
        <img
          :src="backendResourceDomain + image.url"
          alt=""
          class="gallery__img"
        />
      </swiper-slide>
    </swiper>
    <b-container fluid="xl" class="my-5">
      <div v-html="pageData.acf.description" class="description"></div>
      <div class="row my-5">
        <div
          class="col-12 col-md-6 col-xl-4"
          v-if="pageData.acf.parameters && pageData.acf.part_number"
        >
          <p class="text-small text-bold" v-text="pageData.acf.part_number"></p>
          <div
            class="text-small"
            v-if="pageData.acf.parameters"
            v-html="pageData.acf.parameters"
          ></div>
        </div>
        <div class="col-12 col-md-6 col-xl-4" v-if="pageData.acf.dimensions">
          <p class="text-small text-bold">Dimensions</p>
          <div
            class="text-small"
            v-if="pageData.acf.dimensions"
            v-html="pageData.acf.dimensions"
          ></div>
        </div>
        <div
          class="col-12 col-md-6 col-xl-4 m-auto pt-4 pt-xl-0"
          v-if="pageData.acf.files"
        >
          <product-wave :attachments="pageData.acf.files" />
        </div>
      </div>
      <div>
        <img
          :src="backendResourceDomain + pageData.acf.additional_image.url"
          alt=""
          class="img-fluid"
          v-if="pageData.acf.additional_image"
        />
      </div>
      <b-container fluid="xl" class="mb-5" v-if="pageData.acf.equipment_items">
        <divider />
        <h3 class="text-primary text-center my-5">AUXILIARY EQUIPMENT</h3>
        <div class="d-flex flex-wrap justify-content-center align-items-center">
          <div
            class="
              col-12 col-md-4
              d-flex
              flex-wrap
              justify-content-center
              mt-auto
            "
            v-for="item in pageData.acf.equipment_items"
          >
            <h3 class="text-center text-primary text-bold px-2 my-5">
              {{ item.post_title }}
            </h3>
            <img
              :src="backendResourceDomain + item.item_images.sizes.square_large"
              class="extra-section__img"
              v-if="item.item_images.sizes"
            />

            <product-wave
              :attachments="item.files"
              class="my-5"
              v-if="item.files"
            />

            <router-link
              class="
                btn btn-primary
                w-100
                p-3
                mt-auto
                text-small text-uppercase
              "
              :to="'/auxiliary-equipment/' + item.ID"
              >Go to product
            </router-link>
          </div>
        </div>
      </b-container>
      <router-link
        class="btn btn-primary w-100 p-3 mt-auto text-small text-uppercase"
        to="/contact#contact-form"
        >Contact us
      </router-link>
      <divider class="my-3" />
    </b-container>
    <b-container fluid="xl">
      <socials
        :title="pageData.acf.section_seo.meta_title"
        :url="pageData.url"
        :description="pageData.acf.section_seo.meta_description"
        v-if="!isLoading && pageData.url && pageData.acf.section_seo"
      />
      <divider class="mt-3" />
    </b-container>
    <b-container
      class="bg-muted"
      fluid="xl"
      v-if="pageData && pageData.acf && pageData.acf.related_items"
    >
      <h3 class="text-primary text-center my-5">Related Products</h3>
    </b-container>

    <related-items-swiper
      :arrowsVisibility="relatedProductsArrowsVisibility"
      :breakpoints="relatedProductsBreakpoints"
      :slides="pageData.acf.related_items"
      v-if="pageData && pageData.acf && pageData.acf.related_items"
    />

    <div
      class="bg-primary d-flex my-5"
      v-if="pageData && pageData.acf && pageData.acf.case_study_items"
    >
      <b-container fluid="xl" class="bg-primary">
        <h3 class="text-center text-white my-5">Blog</h3>
        <case-studies-item
          v-for="(slide, index) in pageData.acf.case_study_items"
          :image="backendResourceDomain + slide.item_images.sizes.case_studies"
          :id="slide.ID"
          :title="slide.post_title"
          :text="slide.item_short_description"
          :reverse="reverse(index)"
        />
        <router-link
          class="btn bg-white p-3 text-small text-uppercase w-100 text-bold"
          v-if="
            pageData &&
            pageData.acf &&
            pageData.acf.section_6_content &&
            pageData.acf.section_6_content.button &&
            pageData.acf.section_6_content.button.url &&
            pageData.acf.section_6_content.button.anchor
          "
          :to="pageData.acf.section_6_content.button.url"
          v-text="pageData.acf.section_6_content.button.anchor"
          >Read more
        </router-link>
        <router-link
          class="
            btn btn-primary
            p-3
            text-small text-uppercase
            w-100
            text-bold
            redirect-btn
            mt-5
          "
          v-if="
            pageData &&
            pageData.acf &&
            pageData.acf.section_7_content &&
            pageData.acf.section_7_content.button &&
            pageData.acf.section_7_content.button.url &&
            pageData.acf.section_7_content.button.anchor
          "
          :to="pageData.acf.section_7_content.button.url"
          v-text="pageData.acf.section_7_content.button.anchor"
        >
          go to pump solutions
        </router-link>
      </b-container>
    </div>

    <div
      class="d-flex my-5"
      v-else-if="
        pageData &&
        pageData.acf &&
        pageData.acf.section_7_content &&
        pageData.acf.section_7_content.button &&
        pageData.acf.section_7_content.button.url &&
        pageData.acf.section_7_content.button.anchor
      "
    >
      <b-container fluid="xl" class="">
        <router-link
          class="
            btn btn-primary
            p-3
            text-small text-uppercase
            w-100
            text-bold
            redirect-btn
            mt-5
          "
          :to="pageData.acf.section_7_content.button.url"
          v-text="pageData.acf.section_7_content.button.anchor"
        >
          go to pump solutions
        </router-link>
      </b-container>
    </div>
  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import CaseStudiesItem from "../components/CaseStudiesItem.vue";
import Divider from "../components/Divider.vue";
import ProductWave from "../components/ProductWave.vue";
import RelatedItemsSwiper from "../components/RelatedItemsSwiper.vue";
import SectionTitle from "../components/SectionTitle.vue";
import Socials from "../components/Socials.vue";
import Swiper from "../components/Swiper.vue";

export default {
  components: {
    PageSlider,
    SectionTitle,
    ProductWave,
    Divider,
    Socials,
    CaseStudiesItem,
    RelatedItemsSwiper,
    Swiper,
  },
  data: function () {
    return {
      isLoading: true,
      hasData: false,
      backendResourceDomain: "",
      currentPageSlug: 268,
      pageData: [],
      similar: [],
      arrowsVisibility: true,
      relatedProductsArrowsVisibility: true,
      productImagesBreakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
      },
      relatedProductsBreakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
      },
    };
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  async created() {
    this.getProducts();
  },
  watch: {
    $route(to, from) {
      this.getProducts();
    },
  },
  methods: {
    getProducts() {
      axios
        .get("products/" + this.$route.params.id)
        .then((response) => {
          this.pageData = response.data;
          this.hasData = true;
          this.isLoading = false;
          this.pageData.url = window.location.href;
          this.setArrowVisibility();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reverse(index) {
      if (index % 2 == 0) {
        return false;
      } else {
        return true;
      }
    },
    getEquip() {
      axios
        .get("auxiliary-equipment/" + this.$route.params.id)
        .then((response) => {
          this.similar = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setArrowVisibility() {
      this.arrowsVisibility = this.pageData.acf.product_images.length > 1;
      this.relatedProductsArrowsVisibility = this.pageData.acf.related_items.length > 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";

.description {
  p {
    padding: 16px 0;
  }
}

.product-name {
  letter-spacing: 2px;
}

.redirect-btn {
  margin-bottom: -15px;
}

.market__subtitle {
  font-size: 16px;
  font-weight: 700;
}

.gallery__img {
  max-height: 700px;
  max-width: 100%;
  height: auto;
}

.extra-section__img {
  max-height: 360px;
  max-width: 100%;
}

.text {
  &--maritime {
    color: $blue-20;
  }

  &--offshore {
    color: $blue-10;
  }

  &--industrial {
    color: $red;
  }
}
</style>
