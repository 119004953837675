<template>
  <div>
    <div class="row">
      <div
        class="
          col-12 col-md-8
          d-flex
          justify-content-end
          items-align-center
          case-studies
        "
        :class="reverse && 'order-md-2'"
        :style="`background-image: url('${image}')`"
      >
        <router-link
          class="
            btn btn-primary
            p-3
            text-small text-uppercase
            w-75
            mt-auto
            mx-auto
          "
          :to="'/article/' + id"
        >
          Read more
        </router-link>
      </div>
      <div class="col-12 col-md-4 text-white">
        <h2 class="pr-md-4 text-bold text-center text-md-left my-3 mt-md-0">
          {{ title }}
        </h2>
        <p class="mt-4 text-small">{{ text }}</p>
      </div>
    </div>
    <divider class="my-5 bg-white" />
  </div>
</template>

<script>
import Divider from "./Divider.vue";
export default {
  components: { Divider },
  props: {
    reverse: {
      type: Boolean,
      required: false,
    },
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    anchor: {
      type: String,
      required: false,
    },
    url: {
      type: String,
      required: false,
    },
    id: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.case-studies {
  height: 360px;
  background: gray;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 536px) {
    max-height: 430px;
  }
}
</style>
