<template>
  <div class="d-flex flex-column" :class="reverse && 'flex-column-reverse'">
    <div class="wave text-white p-4">
      <slot />
    </div>
    <div class="wave--wrapper">
      <img
        src="/images/blue-wave.svg"
        class="img-fluid"
        :class="reverse && 'img--reverse'"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";
.wave {
  background: $blue-50;
  &--wrapper {
    display: flex;
    margin-left: -5px;
    margin-top: -1px;
    overflow: hidden;
    z-index: 1;
  }
}
.img-fluid {
  width: 100%;
}
.img--reverse {
  transform: rotate(180deg);
}
</style>
