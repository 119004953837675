<template>
  <swiper
    :arrowsVisibility="arrowsVisibility"
    :breakpoints="breakpoints"
    :arrowsLabelVisibility="true"
    class="mt-5 case-studies-swiper"
    id="case-studies-swiper"
    white
    v-if="caseList"
  >
    <swiper-slide v-for="slide in caseList" :key="slide.title">
      <div class="row">
        <div class="col-12 col-md-7">
          <img
            :src="
              backendResourceDomain +
              slide.acf.short_description_image.sizes.case_studies
            "
            alt=""
            class="img-fluid"
            v-if="
              slide.acf &&
              slide.acf.short_description_image &&
              slide.acf.short_description_image.sizes &&
              slide.acf.short_description_image.sizes.case_studies
            "
          />
        </div>
        <div class="col-12 col-md-4 d-flex flex-wrap">
          <h2
            class="text-bold text-white text-md-left text-center py-4 py-md-0"
            v-html="slide.title"
            v-if="slide.title"
          ></h2>
          <router-link
            :to="'case_study/' + slide.id"
            class="
              btn btn-primary
              p-4
              w-100 w-md-75
              text-small text-uppercase text-bold
              mt-auto
              text-center
              mx-auto mx-md-0
              case-studies--read-more
              d-block
            "
          >
            read more about the case
          </router-link>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
import axios from "@/axios";
import Swiper from "./Swiper";

export default {
  name: "CaseStudiesSwiper",
  props: {
    slides: {
      type: Array,
      required: false,
    },
  },
  components: {
    Swiper,
  },
  data() {
    return {
      backendResourceDomain: "",
      caseList: [],
      arrowsVisibility: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
    };
  },
  created() {
    this.getCases();
  },
  methods: {
    getCases() {
      axios
        .get(`case-studies?&page=1`)
        .then((response) => {
          let object = response.data;
          object.pop();
          this.caseList = object;
          this.setArrowsVisibility();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setArrowsVisibility() {
      this.arrowsVisibility = this.slides.length > 1;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/config";

.case-studies {
  &--read-more {
    background: white;
    color: black;
    margin-top: auto;
    @media (min-width: 768px) {
      margin-bottom: auto;
      background: $blue-50;
      color: white;
    }
  }

  &-swiper {
    //margin-bottom: -80px;
  }
}
</style>

<style lang="scss">
#case-studies-swiper {
  .swiper-container {
    max-width: 1145px;
  }
}
