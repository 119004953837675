<template>
  <div class="row py-5 emergency-service" v-if="hasData">
    <div class="col-xl-4 px-4 align-self-center text-bold title">
      {{ support.acf.section_2_content.title }}
    </div>
    <div class="col-xl-3 px-3 align-self-center address">
      <p>{{ support.acf.section_2_content.location_text }}</p>
      <p class="mt-5">{{ support.acf.section_2_content.contact_text }}</p>
    </div>
    <div class="col-xl-5 align-self-center contact">
      <a
        :href="support.acf.section_2_content.phone_button.url"
        v-if="support.acf.section_2_content.phone_button.url"
        class="text-danger text-bold phone"
      >
        {{ support.acf.section_2_content.phone_button.anchor }}
      </a>
      <a
        :href="support.acf.section_2_content.contact_button.url"
        v-if="support.acf.section_2_content.contact_button.url"
        class="
          btn btn-primary
          p-3
          text-small text-uppercase
          w-100
          text-bold
          redirect-btn
          mt-5
        "
        id="contact-form-id"
      >
        {{ support.acf.section_2_content.contact_button.anchor }}
      </a>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";

export default {
  props: {
    button: {
      type: Boolean,
      required: false,
    },
    sectionContent: {
      type: Object,
      required: false,
    },
  },
  data: function () {
    return {
      support: [],
      hasData: false,
    };
  },
  created() {
    this.getSupport();
  },
  methods: {
    getSupport() {
      axios
        .get("custom-pages/support")
        .then((response) => {
          this.support = response.data;
          this.hasData = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.emergency-service {
  .title {
    text-align: center;
    font-size: 2.25rem;
    margin-bottom: 60px;
  }

  .address {
    text-align: left;
  }

  .contact {
    text-align: left;
    font-size: 1.63rem;
  }
}

@media only screen and (min-width: 768px) {
  .emergency-service {
    .title {
      margin-bottom: 12px;
    }

    .address,
    .contact {
      text-align: center;
    }

    .contact {
      font-size: 2.25rem;
      margin-top: 35px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .emergency-service {
    .address,
    .contact {
      text-align: left;
      margin-top: 0;
    }
  }
}
</style>
