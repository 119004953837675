<template>
  <header>
    <nav class="main-navigation fixed-top">
      <div
        class="
          navbar navbar-light
          bg-white
          navbar-expand-xl
          justify-content-start
        "
        v-bind:class="{ show: !show }"
      >
        <b-navbar-brand :to="{ name: 'Home' }">
          <img :src="'/images/logo_marflex.png'" class="img-fluid" alt="" />
        </b-navbar-brand>
        <div class="d-flex align-items-center ml-auto ml-xl-0">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            v-on:click="toggleNavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <button
            class="btn order-1 mr-md-0 mr-xl-auto ml-xl-4"
            v-if="!show && !search"
            @click="showSearchForm"
          >
            <img src="/images/icons/common/loupe-dark.svg" class="img-fluid" />
          </button>
        </div>
        <div class="order-1 mr-md-0 mr-xl-auto ml-xl-4">
          <button class="btn" v-if="show && !search" @click="showSearchForm">
            <img src="/images/icons/common/loupe-dark.svg" class="img-fluid" />
          </button>
        </div>
        <div
          class="navbar-collapse collapse navbar-expand-xl pt-2 pt-md-0"
          id="navbarSupportedContent"
          style="flex-grow: 0"
          v-bind:class="{ show: !show }"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/markets" class="nav-link px-2" exact
                >Markets</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/pump-solutions" class="nav-link px-2" exact
                >Pump solutions</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/service" class="nav-link px-2" exact
                >Service</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/about-us" class="nav-link px-2"
                >About us</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/news" class="nav-link px-2">News</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/blog" class="nav-link px-2">Blog</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/career" class="nav-link px-2"
                >Career</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link px-2"
                >Contact</router-link
              >
            </li>
          </ul>
          <div class="navbar-search">
            <b-input-group v-if="search">
              <b-input-group-prepend>
                <img
                  src="/images/icons/common/loupe-dark.svg"
                  class="img-fluid"
                />
              </b-input-group-prepend>
              <b-form-input
                id="input-search"
                name="input-search"
                v-model="searchInput"
                aria-describedby="input-search-feedback"
                @keyup.enter="searchFor"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" @click="searchFor">
                  <b-icon icon="arrow-right" />
                </b-button>
                <b-button variant="primary" @click="closeSearchForm">
                  <b-icon icon="x" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>

          <b-navbar-nav class="ml-auto">
            <!--          <a href="#" class="btn"><img src="./public/images/icons/common/loupe-dark.svg" /></a>-->

            <!--
          <b-nav-form>
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
          </b-nav-form>
          -->

            <!--
            <b-nav-item-dropdown text="Lang" right>
              <b-dropdown-item href="#">EN</b-dropdown-item>
              <b-dropdown-item href="#">ES</b-dropdown-item>
              <b-dropdown-item href="#">RU</b-dropdown-item>
              <b-dropdown-item href="#">FA</b-dropdown-item>
            </b-nav-item-dropdown>
            -->
          </b-navbar-nav>
        </div>

        <div class="contact text-danger order-2">
          <span class="phone call-us">Call us at:</span>
          <a href="tel:+31 (0)186 890 200" class="phone">+31 (0)186 890 200</a>
          <!-- <span class="info">24/7 EMERGENCY ONLY</span> -->
        </div>
      </div>
      <!--    <div class="mask-overlay" v-bind:class="{ show: !show }">-->
      <div class="mask-overlay">
        <img src="/images/navbar_wave.png" class="img-fluid-contain" />
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      show: true,
      search: false,
      searchInput: ""
    };
  },
  methods: {
    toggleNavbar() {
      this.show = !this.show;
    },
    searchFor() {
      if (this.searchInput.length) {
        this.$router.push({ path: `/search/${this.searchInput}` });
        this.toggleNavbar();
        this.search = false;
        this.searchInput = '';
      }
    },
    showSearchForm() {
      this.toggleNavbar();
      this.search = true;
    },
    closeSearchForm() {
      this.toggleNavbar();
      this.search = false;
    },
    watchScroll() {
      if (window.pageYOffset > 0) {
        this.show = true
      }
    }
  },
  mounted() {
    this.watchScroll();
    document.addEventListener("scroll", this.watchScroll);
  },
  beforeDestroy() {
    document.addEventListener("scroll", this.watchScroll);
  },
};
</script>
<style lang="scss">
@import "src/config";
.main-navigation {
  display: flex;
  flex-direction: column;
  .navbar {
    display: flex;
    width: 100%;
    .navbar-brand img {
      max-width: 80px;
    }
    .navbar-toggler {
      border: none;
    }
    .navbar-nav {
      .nav-item {
        font-size: 1rem;
        .nav-link {
          color: $black;
          &.router-link-active,
          &:hover {
            color: $blue-50;
          }
          &.disabled {
            opacity: 0.3;
          }
        }
      }
      .nav-link {
        text-transform: uppercase;
      }
    }

    .collapse {
      margin-left: 10%;
    }
    .contact {
      text-align: center;
      @media (max-width: 1199px) {
        width: 100%;
      }
      span {
        display: block;
        padding: 0 20px;
      }
      .phone {
        font-size: 24px;
      }
      .info {
        font-weight: 700;
        font-size: 0.8rem;
      }
      .call-us {
        color: #0c2552;
        text-align: left;
        padding: 0;
      }

    }

    .navbar-search {
      position: absolute;
      z-index: 999;
      top: 10px;
      left: 6px;
      width: calc(100% - 15px);
      @media (min-width: 1200px) {
        bottom: -60px;
        width: 900px;
        left: initial;
        top: initial;
      }
      #input-search {
        padding-left: 60px;
      }
      .input-group-prepend {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 15px;
        transform: translate(0, -50%);
      }
    }
  }
}
.mask-overlay {
  line-height: 0;
  background-color: transparent;
  .img-fluid-contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
