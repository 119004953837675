<template>
  <div id="siteFooter" class="footer bg-dark text-white pt-5">
    <b-container fluid="xl" v-if="hasData">
      <div class="row">
        <div class="col-md-6 col-xl-5 text-center text-md-left">
          <h4 class="h3 mb-5">{{ pageData.acf.common_company_name }}</h4>

          <div v-html="pageData.acf.common_company_address"></div>

          <div class="mb-3">Tel: {{ pageData.acf.common_company_phone }}</div>

          <ul v-if="pageData.acf.footer_links_section.footer_links_1">
            <li
              v-for="item in pageData.acf.footer_links_section.footer_links_1"
              class="text-bold text-small text-uppercase py-2"
            >
              <router-link :to="item.footer_links_url" class="text-white">{{
                item.footer_links_anchor
              }}</router-link>
            </li>
          </ul>
        </div>

        <div
          class="
            col-md-6 col-xl-5
            mt-5 mt-md-0
            text-uppercase text-center text-md-right
          "
        >
          <ul
            class="mb-4"
            v-if="pageData.acf.footer_links_section.footer_links_2"
          >
            <li
              v-for="item in pageData.acf.footer_links_section.footer_links_2"
              class="text-bold text-uppercase py-2"
            >
              <a :href="item.footer_links_url" class="text-white">{{
                item.footer_links_anchor
              }}</a>
            </li>
          </ul>

          <ul
            class="mb-4 text-bold text-uppercase"
            v-if="pageData.acf.footer_links_section.footer_links_3"
          >
            <li
              v-for="item in pageData.acf.footer_links_section.footer_links_3"
              class="py-2"
            >
              <a :href="item.footer_links_url" class="text-white">{{
                item.footer_links_anchor
              }}</a>
            </li>
          </ul>
        </div>
        <div
          class="
            col-md-12 col-xl-2 col-third
            d-flex
            flex-column flex-md-row flex-xl-column
            justify-content-center justify-content-xl-start
            py-5
            pt-xl-0
          "
        >
          <div
            class="
              item
              d-flex
              justify-content-center
              flex-column
              align-items-center align-items-xl-end
              mx-md-4 mx-xl-0
              my-2
              mt-xl-0
              mb-xl-4
            "
            v-if="pageData.acf.social_linkedin_url"
          >
            <span class="label">Follow Us:</span>
            <a :href="pageData.acf.social_linkedin_url">
              <img
                src="/images/icons/common/footer-ico-linkedin.svg"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>

          <div
            class="
              item
              d-flex
              justify-content-center
              flex-column
              align-items-center align-items-xl-end
              mx-md-4 mx-xl-0
              my-2
              mt-xl-0
            "
            v-if="pageData.acf.common_company_email"
          >
            <span class="label">Contact Us:</span>
            <a :href="'mailto:' + pageData.acf.common_company_email">
              <img
                src="/images/icons/common/footer-ico-mail.svg"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col text-small my-4 text-center text-md-left text-xl-right">
          <span
            class="copy text-bold d-block d-xl-inline-block pr-1"
            v-text="pageData.acf.footer_copyrights_section.footer_copyrights_1"
          ></span>
          <span
            class="privacy"
            v-text="pageData.acf.footer_copyrights_section.footer_copyrights_2"
          ></span>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import axios from "@/axios";

export default {
  name: "Footer",
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      currentLang: "en",
      pageData: [],
      hasData: false,
    };
  },
  mounted() {
    this.getPageDataBySlug();
  },
  methods: {
    getPageDataBySlug: function () {
      axios
        .get("marflex/configuration")
        .then((response) => {
          // console.log("footer", response.data[0]);
          this.pageData = response.data[0];
          this.hasData = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  ul {
    padding-left: 0;
    list-style: none;
  }
}
</style>
