<template>
  <div>
    <div
      class="row justify-content-center my-3"
      v-for="(product, index) in products"
      :key="product.title"
    >
      <div class="col-xl-4 col-md-6 order-2">
        <h2
          class="text-bold text-md-right text-left title"
          :class="`dedicated-product-text--${product.markets[0].slug}`"
        >
          <!-- <h2 class="text-bold text-md-right text-left" :class="`dedicated-product-text--${category}`"> -->
          {{ product.title }}
        </h2>
        <p class="text-muted text-uppercase text-left text-md-right text-small">
          {{ product.post_type }}
        </p>
        <p
          class="dedicated-product__description d-xl-none"
          v-html="product.acf.short_description"
        ></p>
        <router-link
          :to="'/product/' + product.id"
          :class="`dedicated-product-bg--${product.markets[0].slug}`"
          class="
            btn
            text-small text-uppercase text-bold
            w-100
            dedicated-product__btn
            text-white
            d-flex
            d-xl-none
            align-items-center
            justify-content-center
            mt-auto
          "
        >
          read more
        </router-link>
      </div>
      <div
        class="col-xl-4 col-md-6 order-1 order-md-2 text-center text-md-left"
      >
        <img
          v-if="product.acf.product_images"
          :src="backendResourceDomain + product.acf.product_images[0].sizes.square_large"
          class="dedicated-product__img img-fluid"
        />
      </div>

      <div
        class="
          col-xl-4 col-md-6
          flex-column
          order-3
          text-left text-xl-left
          d-none d-xl-flex
        "
      >
        <p
          class="dedicated-product__description"
          v-html="product.acf.short_description"
        ></p>
        <router-link
          :to="'/product/' + product.id"
          :class="`dedicated-product-bg--${product.markets[0].slug}`"
          class="
            btn
            text-small text-uppercase text-bold
            w-100
            dedicated-product__btn
            text-white
            d-flex
            align-items-center
            justify-content-center
            mt-auto
          "
        >
          read more
        </router-link>
      </div>

      <divider :class="`dedicated-product-bg--${product.markets[0].slug}`" class="col-12 my-4" v-if="index"/>

    </div>
    <router-link
      :to="'/pump-solutions'"
      class="
        btn
        text-small text-uppercase text-bold
        w-100
        dedicated-product__btn
        text-white
        d-flex
        align-items-center
        justify-content-center
        mt-auto
        bg-primary
        my-5
        mt-5
      "
    >
      go to pump solutions
    </router-link>
  </div>
</template>

<script>
import Divider from "./Divider";

export default {
  components: {
    Divider,
  },
  data: function () {
    return {
      backendResourceDomain: "",
    };
  },
  props: {
    products: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";
.dedicated-product {
  .title {
    /* Google Chrome fix */
    overflow-wrap: break-word;
  }
  &__description {
    font-size: 16px;
    @media (min-width: 768px) {
      font-size: 24px;
    }
  }
  &__btn {
    height: 70px;
  }
  &-bg {
    &--maritime {
      background: $blue-20;
    }
    &--offshore {
      background: $blue-10;
    }
    &--industrial {
      background: $red;
    }
  }
  &-text {
    &--maritime {
      color: $blue-20;
    }
    &--offshore {
      color: $blue-10;
    }
    &--industrial {
      color: $red;
    }
  }
}
</style>
