<template>
  <div class="testme">
    <h1>Testme</h1>

    <ContactForm />
  </div>
</template>

<style scoped lang="scss">
$color: red;
h1 {
  color: $color;
}
</style>

<script>
import ContactForm from "/src/components/ContactForm.vue";
export default {
  components: {
    ContactForm,
  },
  data() {
    return {
      loremIpsum: "Lorem ipsum dolor",
      backendUrl: process.env.VUE_APP_BACKEND_URL,
    };
  },
};
</script>
