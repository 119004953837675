<template>
  <swiper :breakpoints="breakpoints" class="swiper">
    <swiper-slide class="related-items-swiper news-slide" v-for="slide in slides">
      <router-link class="btn btn-block bg-primary p-4 text-small text-uppercase text-bold news-read__btn" :to="'/' +
      endpoint + '/' + slide.ID">
        <span class="text-white text-bold text-center">{{ slide.post_title }}</span>
      </router-link>
    </swiper-slide>
  </swiper>
</template>

<script>
import Swiper from "./SwiperVertical";
export default {
  props: ['slides', 'endpoint'],
  data() {
    return {
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 3,
          spaceBetween: 0,
          direction: "vertical",
          autoHeight: true,
          calculateHeight:true,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 0,
          direction: "vertical",
          autoHeight: true,
          calculateHeight:true,
        },
      },
    };
  },
  components: {
    Swiper,
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";
.news {
  &-slide {
    border-bottom: 1px solid $blue-10;
    flex-shrink: 1;
  }
}

</style>

<style>
.swiper {
  height: 100%;
}
.swiper-container {
  height: 90% !important;
}
</style>
