<template>
  <swiper
    :arrowsVisibility="arrowsVisibility"
    :breakpoints="breakpoints"
    :arrowsLabelVisibility="true"
    class="my-5"
    id="news-slide"
  >
    <swiper-slide
      class="related-items-swiper d-flex justify-content-center flex-wrap news-slide"
      v-for="slide in newsList"
      :key="slide.title"
    >
      <div
        class="
          news-thumbnail
          d-flex
          justify-content-center
          align-items-end
          mb-3
        "
        :style="[
          slide.acf.short_description_image &&
          slide.acf.short_description_image.sizes &&
          slide.acf.short_description_image.sizes.square_large
            ? {
                background:
                  'url(' +
                  backendResourceDomain +
                  slide.acf.short_description_image.sizes.square_large +
                  ')',
                  'background-size': 'cover',
              }
            : { background: '#e5e5e5' },
        ]"
      >
        <router-link
          :to="'news/' + slide.id"
          class="
            btn
            text-primary
            bg-white
            p-4
            text-small text-uppercase
            w-75
            text-bold
            news-read__btn
          "
        >
          read more
        </router-link>
      </div>
      <div class="d-flex justify-content-center align-items-center w-100 mt-2 px-2">
        <h6 class="news-date text-primary text-small text-center mx-auto">
          {{ slide.post_date }}
        </h6>
        <badges
          :categories="slide.categories"
          :markets="slide.markets"
        ></badges>
      </div>
      <h3 class="text-primary text-bold text-center mb-4 px-4">{{ slide.title }}</h3>
      <p class="text-small px-4" v-html="slide.acf.description_intro"></p>
    </swiper-slide>
  </swiper>
</template>

<script>
import axios from "@/axios";
import Swiper from "./Swiper";
import Badges from "./Badges";
export default {
  data() {
    return {
      backendResourceDomain: "",
      newsList: [],
      arrowsVisibility: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
          // autoHeight: true,
        },
      },
    };
  },
  components: {
    Swiper,
    Badges,
  },
  created() {
    this.getNews();
  },
  methods: {
    getNews() {
      axios
        .get(`news?&page=1`)
        .then((response) => {
          let object = response.data;
          object.pop();
          this.newsList = object;
          this.setArrowsVisibility();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setArrowsVisibility() {
      this.arrowsVisibility = this.newsList.length > 3;
    },
  },
};
</script>

<style lang="scss">
#news-slide {
  .swiper-container {
    // @media (min-width: 1024px) {
    min-height: 780px;
    // }
  }
}
</style>

<style lang="scss" scoped>
@import "src/config";
#news-slide {
  .swiper-slide {
    height: auto;
  }
  .swiper-container {
    // @media (min-width: 1024px) {
    min-height: 780px;
    // }
  }
}
.news {
  &-slide {
    @media (min-width: 768px) {
      border-bottom: 1px solid #000;
    }
  }
  &-date {
    letter-spacing: 5px;
  }
  &-thumbnail {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 360px;
    background: gray;
    @media (min-width: 768px) {
      height: 350px;
    }
    @media (min-width: 1024px) {
      height: 314px;
    }
    @media (min-width: 1200px) {
      height: 368px;
    }
  }
  &-read__btn {
    margin-bottom: -15px;
  }
}
</style>
