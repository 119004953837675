<template>
  <div class="cookie" :class="containerPosition" v-if="isOpen">
    <b-container fluid="xl" class="px-0 py-4">
      <div class="cookie__content accordion">
        <div class="cookie__title ml-7">Our website uses cookies.</div>

        <b-row class="align-items-baseline">
          <b-col
            v-for="(option, idx) in options"
            :key="idx"
            cols="12"
            xl="4"
          >
            <b-form-checkbox
              v-model="checked"
              :class="option.value === 'analytic' ? `my-3` : 'my-0'"
              :disabled="option.disabled"
              :value="option.value"
              class="item"
              name="flavour-3a"
              size="lg"
            >
              <span>{{ option.text }}</span>

              <b-card
                bg-variant="transparent"
                class="m-0 p-0 border-0"
                no-body
                text-variant="white"
              >
                <b-card-header
                  header-tag="header"
                  class="p-0 border-0 bg-transparent"
                  role="tab"
                >
                  <b-button
                    class="m-0 p-0 mt-2 mb-1 cookie__accordion-button"
                    v-b-toggle="`collapse-` + idx"
                  >
                    <span class="text-white text-lowercase font-italic"
                      ><span class="when-closed">See more</span
                      ><span class="when-opened">See less</span></span
                    >
                    <i
                      aria-hidden="true"
                      class="ico-arrow"
                      v-bind:class="{ rotate: rotatedArrow }"
                    ></i>
                  </b-button>
                </b-card-header>

                <b-collapse :id="`collapse-` + idx">
                  <div class="cookie__description">
                    {{ option.description }}
                  </div>
                </b-collapse>
              </b-card>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col md="6" xl="4" offset-md="6" offset-xl="8">
          <b-btn
            @click="accept"
            block
            class="cookie__button accept bg-white px-3 float-right py-2 mt-3"
            >{{ buttonAccept }}
          </b-btn>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "CookieMessage",
  props: {
    buttonAccept: {
      type: String,
      default: "I agree",
    },
    position: {
      type: String,
      default: "top",
    },
  },
  data() {
    return {
      appCookie: "app",
      isOpen: true,
      checked: ["necessary"],
      rotatedArrow: false,
      options: [
        {
          text: "Technical cookies.",
          value: "necessary",
          description:
            "These cookies are required for a website to function properly and do not require the visitors' consent. If you disable these cookies, some parts of the website may not work properly for you.",
          disabled: true,
        },
        {
          text: "Anonymous analytical cookies.",
          value: "analytic",
          description:
            "We also use analytical cookies that do not infringe your privacy. Because we would like to know how our visitors use the website, so that we can optimise the use of the website, we use Google Analytics. Google Analytics is a web-analysis service offered by Google Inc. Google Analytics uses cookies to analyse the use that visitors make of the website. Google uses aggregated statistical information to provide MarFlex with information as to how visitors use our website. MarFlex uses web service analytics for the purpose of evaluating usage, compiling reports on the activity for our editors, and providing other services relating to webservice activities and internet usage.",
        },
        {
          text: "Marketing cookies.",
          value: "tracking",
          description:
            "Marketing cookies are used to show you ads that are relevant to you. They also reduce the number of times you see an advert and help us to measure the effectiveness of our marketing campaigns. We may also use the information obtained from these cookies to provide you with advertisements that may be of interest to you based on your previous online behaviour.",
        },
      ],
      cookies: [
        { name: "app", type: "necessary" },
        { name: "_gat_UA-44914039-1", type: "tracking" },
        { name: "_ga", type: "tracking" },
        { name: "_gid", type: "tracking" },
        { name: "JabmoSP0id.1fff", type: "tracking" },
        { name: "JabmoSP0ses.1fff", type: "tracking" },
      ],
    };
  },
  computed: {
    containerPosition() {
      return `cookie--${this.position}`;
    },
  },
  created() {
    if (!this.isGuestFromUE()) {
      this.checked.push("analytic", "tracking");
    }
    this.isOpen = !this.getGDPR() === true ? true : false;
  },
  methods: {
    saveConsents() {
      if (this.checked.length > 0) {
        this.$cookies.set(this.appCookie, JSON.stringify(this.checked));
        // console.log("zaznaczono zgody:");
        console.table(JSON.stringify(this.checked));
      } else {
        // console.log("nie zaznaczono zgód...");
      }
    },

    getGDPR() {
      // return localStorage.getItem('GDPR:accepted');
      return this.getCookieJson("app") ? true : false;
    },

    accept() {
      this.isOpen = false;
      this.saveConsents();
      localStorage.setItem("GDPR:accepted", true);
      window.location.reload(false);
    },

    deny() {
      this.isOpen = true;
      localStorage.setItem("GDPR:accepted", false);
    },

    deleteCookie(cookieName) {
      document.cookie =
        cookieName + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },

    listCookies() {
      var theCookies = document.cookie.split(";");
      var aString = "";
      for (var i = 1; i <= theCookies.length; i++) {
        aString += i + " " + theCookies[i - 1] + "\n";
      }
      return aString;
    },

    getCookieJson(c_name) {
      if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
          c_start = c_start + c_name.length + 1;
          var c_end = document.cookie.indexOf(";", c_start);
          if (c_end == -1) {
            c_end = document.cookie.length;
          }
          return unescape(document.cookie.substring(c_start, c_end));
        }
      }
      return "";
    },

    checkCookieValue(c_name, c_key) {
      var json_str = this.getCookieJson("app");
      if (json_str) {
        var items = JSON.parse(json_str);
        return items.indexOf(c_key) !== -1 ? true : false;
      }
      return null;
    },

    isGuestFromUE() {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const eu = [
        "Africa/Ceuta",
        "Asia/Famagusta",
        "Asia/Nicosia",
        "Atlantic/Azores",
        "Atlantic/Canary",
        "Atlantic/Madeira",
        "Europe/Amsterdam",
        "Europe/Athens",
        "Europe/Berlin",
        "Europe/Bratislava",
        "Europe/Brussels",
        "Europe/Bucharest",
        "Europe/Budapest",
        "Europe/Busingen",
        "Europe/Copenhagen",
        "Europe/Dublin",
        "Europe/Helsinki",
        "Europe/Lisbon",
        "Europe/Ljubljana",
        "Europe/Luxembourg",
        "Europe/Madrid",
        "Europe/Malta",
        "Europe/Paris",
        "Europe/Prague",
        "Europe/Riga",
        "Europe/Rome",
        "Europe/Sofia",
        "Europe/Stockholm",
        "Europe/Tallinn",
        "Europe/Vienna",
        "Europe/Vilnius",
        "Europe/Warsaw",
        "Europe/Zagreb",
      ];
      return eu.includes(tz);
    },
  },
};
</script>

<style lang="scss" scoped>
$consentTextColor: #fff;
$labelPadding: 62px;

fieldset {
  display: none;
}

.cookie {
  background: rgba(12, 37, 82, 0.92);
  color: $consentTextColor;
  font-size: 16px;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
  position: fixed;
  width: 100%;
  z-index: 1100;

  &--top {
    top: 0;
  }

  &--bottom {
    bottom: 0;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__accordion-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ico-arrow {
      width: 18px;
      height: 18px;
      align-self: center;
      margin-left: 20px;

      &:before {
        background-image: url("/images/icons/common/card-arrow-white.svg");
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        display: inline-block;
        height: 13px;
        transition: transform 0.2s linear;
        width: 24px;
      }
    }

    .when-opened {
      display: none;
    }

    &.not-collapsed {
      .when-closed {
        display: none;
      }

      .when-opened {
        display: block;
      }

      .ico-arrow {
        &:before {
          transform: rotateZ(180deg);
        }
      }
    }
  }

  &__accordion-button span,
  &__description {
    font-size: 12px;
  }

  &__accordion-button span {
    min-width: 50px;
    text-align: left;
  }

  .btn {
    text-transform: uppercase;
    font-size: 0.88rem;
  }

  ::v-deep .custom-checkbox {
    &.b-custom-control-lg {
      font-size: 16px;
      padding-left: $labelPadding;

      .custom-control-input {
        &:checked ~ .custom-control-label::after {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjUiIGZpbGw9Im5vbmUiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI5IDI1IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogPHBhdGggaWQ9ImNoZWNrYm94LW1hcmsiIGQ9Im0xNS4xNjIgMjMuMjExYy0wLjc2MTQgMS4wMzM1LTEuOTQ3MiAxLjY2ODctMy4yMjkzIDEuNzMxOC0xLjI4MjEgMC4wNjM4LTIuNTIzOC0wLjQ1MjktMy4zODI5LTEuNDA3bC03LjY5ODQtOC41NDMyYy0xLjIyMzUtMS4zNTY5LTEuMTE0OC0zLjQ0ODYgMC4yNDIxLTQuNjcyMSAxLjM1ODItMS4yMjM2IDMuNDQ4Ni0xLjExNDIgNC42NzIyIDAuMjQzNGw1LjQ0NjYgNi4wNDQ3YzAuMDgwNyAwLjA4OTggMC4xOTc5IDAuMTM3OSAwLjMxODMgMC4xMzIxIDAuMTIxLTAuMDA1OSAwLjIzMTctMC4wNjY0IDAuMzAzOS0wLjE2MjdsMTEuMTk1LTE1LjE4NGMxLjA4MzYtMS40NzA4IDMuMTU0NS0xLjc4MzkgNC42MjYtMC42OTk2MiAxLjQ3MDEgMS4wODQyIDEuNzgyNSAzLjE1NTEgMC42OTgzIDQuNjI1M2wtMTMuMTkyIDE3Ljg5MXoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==");
        }

        &[disabled] ~ .custom-control-label,
        &:disabled ~ .custom-control-label {
          color: $consentTextColor;
        }
      }

      .custom-control-label {
        width: 100%;

        &::before,
        &::after {
          background-color: transparent !important;
          height: 40px;
          left: -$labelPadding;
          top: 5px;
          width: 40px;
        }

        &::before {
          border: $consentTextColor solid 1px !important;
        }
      }
    }
  }
}
</style>

<style lang="scss">
$consentTextColor: #fff;
$labelPadding: 62px;

@media (min-width: 1200px) {
  .cookie {
    padding: 0;

    &__accordion-button span,
    &__description {
      font-size: 10px;
    }
  }
}
</style>
