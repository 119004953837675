<template>
  <main class="main news">
    <page-slider
      :slides="pageData.acf.banner_group.slides"
      v-if="pageData && pageData.acf && pageData.acf.show_banner"
    ></page-slider>
    <div class="py-4" v-else></div>

    <b-container fluid="xl" class="my-5">
      <section-title
        v-text="pageData.acf.title"
        v-if="pageData && pageData.acf && pageData.acf.title"
        >Welcome to Our Product list</section-title
      >
    </b-container>

    <b-container fluid="xl" class="px-0">
      <form-category-market
        :firstFilter="filters.product_category"
        :secondFilter="filters.market_category"
        :allowOrderBy="false"
        :allowPagination="false"
        @chooseFirstFilter="chooseFirstFilter"
        @chooseSecondFilter="chooseSecondFilter"
      />
    </b-container>

    <b-container fluid="xl" v-if="hasData">
      <articles-list-item
        v-for="item in articlesList"
        :key="item.id"
        :title="item.title"
        :excerpt="item.acf.short_description"
        :date="item.poast_date"
        :categories="item.categories"
        :markets="item.markets"
        :image="
        item &&
        item.acf &&
        item.acf.product_images[0] &&
        item.acf.product_images[0].sizes ? backendResourceDomain + item.acf.product_images[0].sizes.square_large : ''"
        :id="item.id"
      >
        <b-container fluid="xl" class="my-3 px-10 px-md-5 px-xl-2">
          <divider class="px-xl-0" />
        </b-container>
      </articles-list-item>

      <b-container
        fluid="xl"
        class="px-0 my-3 text-center"
        v-if="see_more && page < filters.total_pages"
      >
        <a @click="seeMore" class="px-0 my-5 text-uppercase more">
          <span>see more</span>
          <br />
          <img src="/images/icons/common/icon-down-arrow.svg" alt="" />
        </a>
      </b-container>
    </b-container>
  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import SectionTitle from "../components/SectionTitle.vue";
import FormCategoryMarket from "../components/FormCategoryMarket";
import ArticlesListItem from "../components/ProductsListItem";
import Divider from "../components/Divider";

export default {
  components: {
    PageSlider,
    SectionTitle,
    FormCategoryMarket,
    ArticlesListItem,
    Divider,
  },
  data() {
    return {
      isLoading: true,
      hasData: false,
      backendResourceDomain: "",
      currentPageSlug: "pump-solutions",
      pageData: [],
      filters: {},
      articlesList: [],
      product_category: "",
      market_category: "",
      per_page: 5,
      page: 1,
      see_more: true,
    };
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  created() {
    this.getProducts();
    this.getProductsPage();
  },
  methods: {
    getProductsPage() {
      axios
        .get("custom-pages/" + this.currentPageSlug)
        .then((response) => {
          this.pageData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProducts() {
      axios
        // .get("pages?slug=" + this.currentPageSlug)
        // ?product_category=${this.product_category}&market_category=${this.market_category}&per_page=${this.per_page}&page=${this.page}
        .get(
          `products?product_category=${this.product_category}&market_category=${this.market_category}&per_page=${this.per_page}&page=${this.page}`
        )
        .then((response) => {
          let length = response.data.length - 1;
          this.filters = response.data[length];

          let object = response.data;
          object.pop();
          this.articlesList = object;

          this.hasData = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    chooseFirstFilter(item) {
      this.page = 1;
      this.product_category = item;
      this.getProducts();
    },
    chooseSecondFilter(item) {
      this.page = 1;
      this.market_category = item;
      this.getProducts();
    },
    seeMore() {
      this.page++;

      axios
        .get(
          `products?product_category=${this.product_category}&market_category=${this.market_category}&per_page=${this.per_page}&page=${this.page}`
        )
        .then((response) => {
          let object = response.data;
          object.pop();

          if (object.length) {
            this.articlesList.push(...object);
          } else {
            this.see_more = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.more {
  font-weight: 700;
}
</style>
