<template>
  <div class="d-flex flex-wrap col-12 bg-primary text-white px-4 pt-4 px-md-4 py-md-2 align-items-center mb-2 item">
    <div class="d-flex flex-wrap col-md-8 col-sm-12">
      <span class="text-bold text-uppercase" v-html="title + ' /'"></span>
      <span class="col-12 col-lg-auto col-xl-auto px-xl-2 px-0" v-if="subtitle" v-html="subtitle"></span>
    </div>
    <div class="col-md-4 col-sm-12"><slot></slot></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: String,
  },
};
</script>

<style lang="scss" scoped>
.item {
  min-height: 100px;
}
</style>
