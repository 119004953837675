import { render, staticRenderFns } from "./RelatedNewsSwiper.vue?vue&type=template&id=487cb972&scoped=true&"
import script from "./RelatedNewsSwiper.vue?vue&type=script&lang=js&"
export * from "./RelatedNewsSwiper.vue?vue&type=script&lang=js&"
import style0 from "./RelatedNewsSwiper.vue?vue&type=style&index=0&id=487cb972&prod&lang=scss&scoped=true&"
import style1 from "./RelatedNewsSwiper.vue?vue&type=style&index=1&id=487cb972&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487cb972",
  null
  
)

export default component.exports