<template>
  <main class="main default-template">
    <page-slider
      :slides="pageData.acf.banner_group.slides"
      v-if="pageData && pageData.acf && pageData.acf.show_banner"
    ></page-slider>
    <div class="py-4" v-else></div>

    <b-container fluid="xl" class="my-5" v-if="pageData">
      <SectionTitle class="mb-5" v-if="pageData.title">{{
        pageData.title
      }}</SectionTitle>

      <div v-for="item in pageData.acf.sections">
        <div
          class="subtitle py-2 text-bold"
          v-text="item.title"
          v-if="item.title"
        ></div>
        <div class="text-small" v-html="item.text" v-if="item.text"></div>
      </div>
    </b-container>
  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import SectionTitle from "../components/SectionTitle";

export default {
  components: {
    PageSlider,
    SectionTitle,
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  data() {
    return {
      pageData: [],
      isLoading: true,
    };
  },
  async created() {
    this.getData();
  },
  watch: {
    $route(to, from) {
      this.getData();
    },
  },
  methods: {
    getData: function () {
      axios
        .get("custom-pages/" + this.$route.meta.endpoint)
        .then((response) => {
          this.pageData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
