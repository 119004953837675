var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.caseList)?_c('swiper',{staticClass:"mt-5 case-studies-swiper",attrs:{"arrowsVisibility":_vm.arrowsVisibility,"breakpoints":_vm.breakpoints,"arrowsLabelVisibility":true,"id":"case-studies-swiper","white":""}},_vm._l((_vm.caseList),function(slide){return _c('swiper-slide',{key:slide.title},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-7"},[(
            slide.acf &&
            slide.acf.short_description_image &&
            slide.acf.short_description_image.sizes &&
            slide.acf.short_description_image.sizes.case_studies
          )?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.backendResourceDomain +
            slide.acf.short_description_image.sizes.case_studies,"alt":""}}):_vm._e()]),_c('div',{staticClass:"col-12 col-md-4 d-flex flex-wrap"},[(slide.title)?_c('h2',{staticClass:"text-bold text-white text-md-left text-center py-4 py-md-0",domProps:{"innerHTML":_vm._s(slide.title)}}):_vm._e(),_c('router-link',{staticClass:"btn btn-primary p-4 w-100 w-md-75 text-small text-uppercase text-bold mt-auto text-center mx-auto mx-md-0 case-studies--read-more d-block",attrs:{"to":'case_study/' + slide.id}},[_vm._v(" read more about the case ")])],1)])])}),1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }