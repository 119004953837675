<template>
  <swiper v-if="isRender()" :arrowsVisibility="arrowsVisibility" arrowsLabelVisibility="true">
    <template v-for="slide in eventsList">
      <swiper-slide
        class="d-flex flex-wrap align-items-start"
        v-if="slide.categories[0].name == 'Events'"

      >
        <div
          class="col-12 col-md-6 col-xl-4 text-center order-2 order-md-1 mt-5"
        >
          <div
            class="
              d-flex
              align-items-center
              justify-content-md-end justify-content-center
            "
          >
            <h6
              class="
                event-date
                text-primary text-small text-center
                mr-md-2
                mx-auto
              "
            >
              {{ slide.post_date }}
            </h6>
            <badges
              :categories="slide.categories"
              :markets="slide.markets"
            ></badges>
          </div>
          <h2
            class="
              text-primary text-bold text-md-right text-center
              mt-md-5
              mb-4 mb-md-0
            "
          >
            {{ slide.title }}
          </h2>
        </div>
        <div
          class="
            col-12 col-md-6 col-xl-4
            order-3 order-md-4 order-xl-3
            mt-md-2 mt-xl-0
          "
        >
          <p v-html="slide.acf.description_intro" class="col-12"></p>
          <div class="text-center d-none d-xl-flex mb-2 mt-auto">
            <router-link
              class="
                btn btn-primary
                p-4
                w-75
                text-small text-uppercase text-bold
                mt-4
                text-center
                mx-auto
              "
              :to="'news/' + slide.id"
            >
              read more
            </router-link>
          </div>
        </div>
        <div
          class="
            text-center
            col-12 col-md-6 col-xl-4
            order-3
            d-flex
            mb-2
            d-xl-none
          "
        >
          <router-link
            class="
              btn btn-primary
              p-4
              w-75
              text-small text-uppercase text-bold
              mt-4 mt-md-auto
              text-center
              mx-xl-auto
              ml-md-auto
              mx-auto
            "
            :to="'news/' + slide.id"
          >
            read more
          </router-link>
        </div>
        <div
          class="
            col-12 col-md-6
            order-1
            col-xl-4
            event-thumbnail
            order-md-2 order-xl-3
          "
          :style="
            'background-image: url(' +
            backendResourceDomain +
            slide.acf.short_description_image.sizes.square_large +
            ')'
          "
          alt=""
        ></div>
      </swiper-slide>
    </template>
  </swiper>
</template>

<script>
import axios from "@/axios";
import Swiper from "./Swiper";
import Badges from "./Badges";
export default {
  components: {
    Swiper,
    Badges,
  },
  data() {
    return {
      backendResourceDomain: "",
      eventsList: [],
      arrowsVisibility: true,
    };
  },

  created() {
    this.getNews();
  },
  methods: {
    getNews() {
      axios
        .get(`news?&page=1`)
        .then((response) => {
          let object = response.data;
          object.pop();
          this.eventsList = object;
          this.setArrowVisibility();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    isRender(){
      this.eventsList ? true : false;
    },
    setArrowVisibility(){
      this.arrowsVisibility = this.eventsList.length > 1;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";
.event {
  &__btn {
    height: 70px;
  }
  &-date {
    letter-spacing: 5px;
  }
  &-thumbnail {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 360px;
    background: gray;
    @media (min-width: 768px) {
      height: 350px;
    }
    @media (min-width: 1024px) {
      height: 314px;
    }
    @media (min-width: 1200px) {
      height: 368px;
    }
  }
  &-read__btn {
    margin-bottom: -15px;
  }
}
</style>
