<template>
  <main class="main home" ref="homeCont">
    <loading-overlay
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
    />

    <page-slider
      :slides="pageData.acf.banner_group.slides"
      v-if="pageData && pageData.acf && pageData.acf.show_banner"
    ></page-slider>
    <div class="py-4" v-else></div>

    <b-container fluid="xl" class="mainContainer">
      <!-- <div v-if="pageData && pageData.acf">
        {{pageData.acf.section_seo.meta_title}}
      </div> -->
      <div
        class="
          row
          justify-content-center
          flex-column flex-md-row
          justify-content-md-around
          align-items-center
        "
        v-if="pageData && pageData.acf && pageData.acf.section_1_active"
      >
        <div
          class="mb-4 mr-mb-2"
          v-if="hasData"
          v-for="(market, index) in pageData.acf.section_1_content.title"
        >
          <div
            class="market-category position-relative"
            :style="
              'background-image: url(' +
              backendResourceDomain +
              market.image.sizes.square +
              ')'
            "
            @click="goToPage('markets/' + market.market.post_name + '/' + index)"
          >
            <img
              :src="'/images/' + market.market.post_name + '-wave.svg'"
              class="market-category__wave"
              alt=""
            />
            <h4
              class="text-uppercase text-bold market-category__title text-white"
            >
              {{ market.market.post_title }}
            </h4>
          </div>
          <router-link
            :to="'markets/' + market.market.post_name + '/' + index"
            class="btn text-center text-primary w-100 text-small py-3"
          >
            {{market.button_label}}
            <img
              src="/images/icons/common/icon-right-arrow-primary.svg"
              class="ml-3"
            />
          </router-link>
        </div>
        <!-- <div class="mb-4 mr-mb-2">
          <div class="market-category position-relative" style="background-image: url('/images/benjamin.jpg')">
            <img src="/images/offshore-wave.svg" class="market-category__wave" alt="" />
            <h4 class="text-uppercase text-bold market-category__title text-white">Offshore</h4>
          </div>
          <a class="btn text-center text-primary w-100 text-small py-3">
            find out more
            <img src="/images/icons/common/icon-right-arrow-primary.svg" class="ml-3" />
          </a>
        </div>
        <div class="mb-4 mr-mb-2">
          <div class="market-category position-relative" style="background-image: url('/images/benjamin.jpg')">
            <img src="/images/industrial-wave.svg" class="market-category__wave" alt="" />
            <h4 class="text-uppercase text-bold market-category__title text-white">Industrial</h4>
          </div>
          <a class="btn text-center text-primary w-100 text-small py-3">
            find out more
            <img src="/images/icons/common/icon-right-arrow-primary.svg" class="ml-3" />
          </a>
        </div> -->
      </div>
      <p
        class="text-primary text-center"
        v-if="hasData && pageData.acf && pageData.acf.section_1_active"
      >
        {{ pageData.acf.section_1_content.description }}
      </p>

      <section-title
        class="my-4"
        v-if="hasData && pageData.acf && pageData.acf.section_2_active"
        >{{ pageData.acf.section_2_content.title }}</section-title
      >
    </b-container>
    <div
      class="bg-primary"
      v-if="hasData && pageData.acf && pageData.acf.section_2_active"
    >
      <b-container fluid="xl" class="text-white">
        <div class="row py-5">
          <div
            v-for="card in pageData.acf.section_2_content.cards"
            class="
              col-12 col-md-4
              d-flex
              justify-content-center
              flex-column
              align-items-center
              my-5
            "
          >
            <img :src="`/images/icons/common/` + card.icon + `.svg`" />
            <h3 class="text-bold">{{ card.title }}</h3>
            <p class="my-4 text-center mb-auto">
              {{ card.description }}
            </p>
          </div>
        </div>
      </b-container>
    </div>
    <b-container
      fluid="xl"
      v-if="hasData && pageData.acf && pageData.acf.section_3_active"
    >
      <h3 class="text-center text-primary my-5">
        {{ pageData.acf.section_3_content.title }}
      </h3>
    </b-container>

    <news-swiper />

    <events-swiper />

    <div
      class="worth-reading"
      v-if="hasData && pageData.acf && pageData.acf.section_4_active"
    >
      <div class="worth-reading--overlay">
        <b-container fluid="xl" class="py-5">
          <h3
            class="text-center text-white"
            v-text="pageData.acf.section_4_content.title"
          >
            Worth reading
          </h3>
          <h6
            class="text-small text-uppercase text-center text-white"
            v-text="pageData.acf.section_4_content.subtitle"
          >
            Our blog
          </h6>
          <div class="row text-white my-5">
            <div
              class="col-12 col-md-6 col-xl-4 d-flex flex-wrap flex-column mt-5"
              v-for="article in blogList"
              :key="article.title"
            >
              <h6 class="text-bold text-center">{{ article.title }}</h6>
              <p class="text-small mt-auto">
                {{ article.acf.description_intro }}
              </p>
              <router-link
                :to="'article/' + article.id"
                class="
                  text-uppercase text-bold text-small text-white text-center
                "
              >
                read the rest of the article
                <img
                  src="/images/icons/common/icon-right-arrow.svg"
                  alt=""
                  class="ml-3"
                />
              </router-link>
              <div class="worth-reading__divider d-md-none" />
            </div>
            <router-link
              to="blog"
              class="
                text-center text-white
                bg-transparent
                w-100
                my-5
                text-uppercase
                blog-href
                d-flex
                justify-content-center
                align-items-center
                text-bold
                mx-3
              "
              v-text="pageData.acf.section_4_content.button_label"
            >
            </router-link>
          </div>
        </b-container>
      </div>
    </div>
    <blue-wave-container
      reverse
      v-if="hasData && pageData.acf && pageData.acf.section_5_active"
    >
      <h3
        class="text-white text-center"
        v-text="pageData.acf.section_5_content.title"
      ></h3>
      <h6
        class="text-uppercase text-center latest-cases text-small"
        v-text="pageData.acf.section_5_content.subtitle"
      ></h6>

      <b-container fluid="hd" class="p-0">
        <case-studies-swiper />
      </b-container>

    </blue-wave-container>

    <b-container fluid="xl" class="py-5">
      <router-link
        :to="'case-studies/'"
        class="
          btn btn-primary
          p-4
          w-100
          text-small text-uppercase text-bold text-center
          all-case-studies
          d-block
          mx-auto
        "
        v-if="hasData && pageData.acf && pageData.acf.section_5_active"
        v-text="pageData.acf.section_5_content.button_label"
      >
        see all case studies
      </router-link>
    </b-container>
  </main>
</template>
<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import BlueWaveContainer from "../components/BlueWaveContainer.vue";
import EventsSwiper from "../components/EventsSwiper.vue";
import NewsSwiper from "../components/NewsSwiper.vue";
import CaseStudiesSwiper from "../components/CaseStudiesSwiper.vue";
import SectionTitle from "../components/SectionTitle.vue";
export default {
  components: {
    PageSlider,
    SectionTitle,
    NewsSwiper,
    EventsSwiper,
    CaseStudiesSwiper,
    BlueWaveContainer,
  },
  name: "Home",
  data() {
    return {
      isLoading: true,
      fullPage: false,
      loader: "bars",
      backendResourceDomain: "",
      currentPageSlug: "home",
      hasData: false,
      pageData: [],
      blogList: [],
    };
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  mounted() {
    this.getPageDataBySlug();
    this.getBlog();
    //this.getCases();
  },
  methods: {
    getPageDataBySlug: function () {
      axios
        .get("custom-pages/" + this.currentPageSlug)
        .then((response) => {
          this.pageData = response.data;
          this.hasData = true;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBlog() {
      axios
        .get(`blog-articles?&page=1`)
        .then((response) => {
          let object = response.data;
          object.pop();
          this.blogList = object;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToPage(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/config";
.all-case-studies {
  max-width: 1500px;
}

.mainContainer {
  margin-top: -15px;
  position: relative;
  z-index: 99;
}

.market-category {
  width: 320px;
  height: 320px;
  background: rgb(6, 132, 216);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  &__wave {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
  }
  &__title {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    font-size: 48px;
  }

  @media (min-width: 768px) {
    width: 230px;
    &__title {
      font-size: 38px;
    }
  }
  @media (min-width: 1024px) {
    width: 320px;
  }
  @media (min-width: 1200px) {
    width: 368px;
    &__title {
      font-size: 48px;
    }
  }
}
.latest-cases {
  font-weight: 300;
}
.worth-reading {
  background: url("/images/worth-reading.jpg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: -120px;
  min-height: 733px;
  background-size: cover;
  z-index: -1;
  &--overlay {
    background: rgba(12, 37, 82, 0.6);
    height: 100%;
    width: 100%;
  }
  &__divider {
    height: 1px;
    background: white;
    margin: 40px 0;
  }
}
.blog-href {
  border: 1px solid white;
  height: 70px;
}
</style>
