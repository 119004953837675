<template>
  <main class="main">
    <page-slider
      :slides="pageData.acf.banner_group.slides"
      v-if="pageData && pageData.acf && pageData.acf.show_banner"
    ></page-slider>
    <div class="py-4" v-else></div>

    <b-container fluid="xl" v-if="pageData.acf">
      <section-title class="my-5">{{ pageData.acf.title }}</section-title>
      <p v-html="pageData.acf.text"></p>
      <h3 class="text-primary text-center my-5">
        Choose the market of interest
      </h3>
      <div class="row">
        <div
          v-for="(tab, index) in marketTabs"
          class="
            col-12 col-md-4
            my-3 my-md-0
            market-tab
          "
          :class="
            activeTab === index
              ? `text-white market-active--${tab.slug}`
              : `text--${tab.slug}`
          "
          @click="
            activeTab = index;
            market_category = tab.slug;
            getProducts();
          "
          :key="tab.slug"
        >
          <div class="position-relative overflow-hidden">
            <img
              :src="
                activeTab === index
                  ? `/images/tab_market_${tab.slug}.svg`
                  : '/images/tab_market_transparent.svg'
              "
              :style="[
                activeTab === index ? { width: '101%' } : { width: '100%' },
              ]"
            />
            <h3 class="market-tab__text text-bold text-uppercase" v-if="tab.title">{{ tab.title }}</h3>
          </div>
          <div
            class="d-md-none"
            :class="
              activeTab === index
                ? `d-block market-tab--${marketTabs[activeTab].slug} text-small p-2`
                : 'd-none'
            "
            v-html="tab.acf.description"
          >
          </div>
        </div>
        <div
          v-if="chosenMarket"
          class="market-tab__content p-4 text-white text-small"
          :class="`market-tab--${marketTabs[activeTab].slug} d-none d-md-block`"
        >
          <p v-html="marketTabs[activeTab].acf.description"></p>
        </div>
      </div>
      <h5
        v-if="chosenMarket"
        class="text-center my-5"
        :class="`text--${marketTabs[activeTab].slug}`"
      >
        Dedicated products
      </h5>

      <dedicated-product
        class="mt-5"
        v-show="productsList"
        :products="productsList"
      />

      <!-- :category="marketTabs[activeTab].slug" -->
    </b-container>
  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import DedicatedProduct from "../components/DedicatedProduct.vue";
import SectionTitle from "../components/SectionTitle.vue";
export default {
  components: {
    PageSlider,
    SectionTitle,
    DedicatedProduct,
  },
  data() {
    return {
      isLoading: true,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      currentPageSlug: "markets",
      pageData: [],
      market_category: "",
      chosenMarket: false,

      marketTabs: [],
      productsList: {},
      activeTab: null,
    };
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  created() {
    if (this.$route.params.slug) {
      this.activeTab = parseInt(this.$route.params.tab);
      this.market_category = this.$route.params.slug;
      this.getProducts();
    }

    this.getMarkets();
    this.getMarketsDetails();
  },
  methods: {
    getMarkets() {
      axios
        .get("custom-pages/" + this.currentPageSlug)
        .then((response) => {
          // console.log("page market", response);
          this.pageData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMarketsDetails() {
      axios
        .get("markets?lang=en")
        .then((response) => {
          // console.log("markets", response);
          this.marketTabs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProducts() {
      this.chosenMarket = true;

      axios
        .get(`products?&market_category=${this.market_category}`)
        .then((response) => {
          //console.log("prod", response);
          let object = response.data;
          object.pop();
          this.productsList = object;
          // this.hasData = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";
.text {
  &--maritime {
    color: $blue-20;
  }
  &--offshore {
    color: $blue-10;
  }
  &--industrial {
    color: $red;
  }
}
.market-active {
  &--maritime::before,
  &--offshore::before,
  &--industrial::before {
    content: "";
    width: calc(100% - 30px);
    height: 24px;
    bottom: -20px;
    position: absolute;
  }
  &--maritime::before {
    background: $blue-20;
  }
  &--offshore::before {
    background: $blue-10;
  }
  &--industrial::before {
    background: $red;
  }
}
.market-tab {
  position: relative;
  cursor: pointer;
  &__content {
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15.5px;
    width: 100%;
  }

  &--maritime {
    background: $blue-20;
  }
  &--offshore {
    background: $blue-10;
  }
  &--industrial {
    background: $red;
  }

  &__text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
  }
}
</style>
