<template>
  <div class="blog">
    <h1 v-if="dataFetched" v-html="post.title.rendered"></h1>

    <p>Current route name: {{ $route.name }}</p>

    <p v-if="dataFetched" v-html="post.content.rendered"></p>

    <b-button v-on:click="fetchPost">Get post</b-button>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      dataFetched: false,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      post: {},
      queryOptions: {
        _embed: true, //Response should include embedded resources.
      },
    };
  },
  created() {
    this.fetchPost();
  },
  methods: {
    fetchPost() {
      axios
        .get("posts?slug=" + this.$route.params.slug, {
          params: this.queryOptions,
        })
        .then((response) => {
          this.post = response.data[0];
          this.dataFetched = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
