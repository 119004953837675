<template>
  <main class="main contact">
    <GoogleMap />

    <b-container fluid="xl" class="px-0">
      <hr class="mt-5 d-none d-md-block" />
      <EmergencyService />
      <hr class="mb-5" />
      <div>
        <ContactForm
          :id="pageData.acf.section_6_content.contact_form_id"
          v-if="
            pageData.acf &&
            pageData.acf.section_6_active &&
            pageData.acf.section_6_content.contact_form_id
          "
        />
      </div>
    </b-container>
  </main>
</template>

<style></style>

<script>
import axios from "@/axios";
import GoogleMap from "/src/components/GoogleMap.vue";
import ContactForm from "/src/components/ContactForm.vue";
import EmergencyService from "/src/components/EmergencyService.vue";

export default {
  components: {
    GoogleMap,
    ContactForm,
    EmergencyService,
  },
  data() {
    return {
      isLoading: true,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      currentPageSlug: "contact",
      pageData: [],
    };
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  created() {
    this.getContact();
  },
  mounted() {
    if(this.$route.hash) {
      setTimeout(() => {
        const form = document.getElementById("contact-form");
        const position = form.getBoundingClientRect();
        window.scrollTo({
          top: position.top - 100,
          left: position.left,
          behavior: "smooth",
        });
      }, 2500);
    }
  },
  methods: {
    getContact() {
      axios
        .get("custom-pages/contact")
        .then((response) => {
          // console.log("contact page", response);
          this.pageData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
