<template>
  <swiper :arrowsVisibility="arrowsVisibility" :breakpoints="breakpoints">
    <swiper-slide
      class="related-items-swiper d-flex flex-wrap"
      v-for="slide in slides"
      :key="slide.post_name"
    >
      <div class="col-12 col-md-6 col-xl-4 order-md-2 order-xl-3 text-center">
        <img
          :src="backendResourceDomain + slide.item_images[0].sizes.square_large"
          class="img-fluid"
          alt=""
          v-if="
            slide.item_images &&
            slide.item_images[0].sizes &&
            slide.item_images[0].sizes.square_large
          "
        />
      </div>

      <div class="col-12 col-md-6 col-xl-4 text-right order-md-1 order-xl-1">
        <p
          class="
            text-small text-muted text-uppercase text-center text-md-right
            my-5 my-md-0
          "
        >
          {{ slide.post_name }}
        </p>
        <h2 class="related-items__title text-primary text-bold text-center text-md-right">
          {{ slide.post_title }}
        </h2>
      </div>

      <div class="d-none d-xl-none d-md-flex col-md-6 order-md-3 mt-4 mt-xl-0">
        <router-link
          class="
            btn btn-primary
            p-4
            w-75
            text-small text-uppercase text-bold
            mt-4 mt-md-auto
            text-center
            mx-auto
            button-bottom
          "
          :to="'/product/' + slide.ID"
        >
          read more
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-xl-4 order-md-4 order-xl-2 mt-4 mt-xl-0">
        <div
          v-html="slide.item_short_description"
          class="description d-sm-flex"
        ></div>
        <div
          class="text-center d-md-none d-xl-flex justify-content-center mb-2"
        >
          <router-link
            class="
              btn btn-primary
              p-4
              w-75
              text-small text-uppercase text-bold
              mt-4 mt-md-auto
              text-center
              mx-auto
              button-bottom
            "
            :to="'/product/' + slide.ID"
          >
            read more
          </router-link>
        </div>
      </div>

      <!-- <div
        class="col-12 col-md-6 order-1 col-xl-4 related-items__img order-md-2"
        :style="`background-image: url('${slide.image}')`"
        alt=""
      ></div> -->
    </swiper-slide>
  </swiper>
</template>

<script>
import Swiper from "./Swiper.vue";

export default {
  data() {
    return {
      backendResourceDomain: ""
    };
  },
  components: { Swiper },
  props: {
    arrowsVisibility: {
      type: Boolean,
      required: false
    },
    breakpoints: {
      type: Object,
      required: false
    },
    slides: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper {
  max-width: 1500px;
}

.description {
  font-size: 16px;
}

.related-items {
  &__title {
    overflow-wrap: break-word;
  }
  &__img {
    height: 360px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.swiper-button-next--custom {
  transform: rotate(180deg);
}

.icon {
  height: 24px;
}

@media only screen and (min-width: 768px) {
  .button-bottom {
    position: absolute;
    bottom: 0px;
  }
}
</style>
