<template>
  <div class="pdf d-flex flex-column">
    <div
      class="pdf__header text-center text-primary text-bold px-4 pt-4 mb-1"
      v-if="cardTitle"
    >
      {{ cardTitle }}
    </div>

    <div class="pdf__content px-4 pb-2 mb-1" v-if="attachments">
      <div
        class="text-small text-primary my-2"
        v-for="pdf in attachments"
        :key="pdf.file.title"
      >
        - {{ pdf.title }}
        <a
          :href="backendResourceDomain + pdf.file.url"
          class="text-uppercase pdf__href"
          >... download pdf</a
        >
      </div>
    </div>

    <div class="pdf__footer position-relative">
        <img src="/images/product_wave.svg" alt="" class="img-fluid-contain" />
        <img
          src="/images/icons/common/pdf.svg"
          alt=""
          class="position-absolute pdf-icon"
        />
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      backendResourceDomain: "",
    };
  },
  props: ["attachments", "cardTitle"],
};
</script>

<style lang="scss" scoped>
@import "src/config";

.pdf {
  min-height: 257px;
  position: relative;
  border: 1px solid $blue-50;

  &-icon {
    left: 50%;
    top: 59%;
    transform: translate(-50%, -50%);
    height: 60%;
  }

  &__href {
    color: $red;
  }

  &__header {}

  &__content {}

  &__footer {
    margin-top: auto;
    align-self: flex-end;
    flex-direction: column;
    overflow: hidden;
    .img-fluid-contain {
      width: 102%;
      height: auto;
    }
  }
}
</style>
