<template>
  <div id="app">
    <navbar :name="$route.name" />
    <contact-bar />
    <transition name="route" mode="out-in">
      <div style="min-height: 100vh">
        <router-view></router-view>
      </div>
    </transition>
    <div style="position: relative">
      <cookie-message position="bottom" button-accept="I agree"/>
    </div>
    <page-footer :name="$route.name" />
  </div>
</template>

<script>
import Navbar from "/src/components/Navbar.vue";
import PageFooter from "/src/components/Footer.vue";
import ContactBar from "./components/ContactBar.vue";
import CookieMessage from "./components/CookieMessage";

export default {
  name: "App",
  components: {
    CookieMessage,
    Navbar,
    PageFooter,
    ContactBar,
  },
};
</script>

<style>
.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
