<template v-if="slides.length">
  <div class="header-slider">
    <b-carousel
      id="carousel-1"
      :interval="4000"
      fade
      controls
      indicators
      background="#ffffff"
      img-width="1920"
      img-height="650"
    >
      <b-carousel-slide
        v-for="(item, idx) in slides"
        :key="idx"
        :img-src="backendResourceDomain + item.slide_image.url"
        :class="[item.slide_theme]"
      >
        <div class="title" v-html="item.slide_title" v-if="item.slide_title"></div>
        <a :href="item.slide_url" v-if="item.slide_url" class="btn">
          <span v-text="item.slide_url_anchor">find out more</span>
          <i class="ico-arrow-next"></i>
        </a>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      backendResourceDomain: "",
      slide: 0,
      sliding: null,
    };
  },
  props: ["slides"],
};
</script>

<style lang="scss">
@import "src/config";
@import "src/functions";
.header-slider {
  .btn {
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    &:hover {
      opacity: 0.5;
    }
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='24' fill='none' version='1.1' viewBox='0 0 13 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.997 23.994c0.2881 6e-4 0.522-0.2326 0.5226-0.5206 2e-4 -0.1389-0.0549-0.2722-0.1533-0.3703l-11.107-11.106 11.107-11.106c0.204-0.20396 0.204-0.53462 0-0.73858-0.2039-0.20396-0.5346-0.20396-0.7386 0l-11.475 11.475c-0.20362 0.2037-0.20362 0.5339 0 0.7376l11.475 11.475c0.0978 0.0981 0.2308 0.1533 0.3693 0.1533z' fill='%23000'/%3E%3C/svg%3E%0A");
  }
  .carousel-control-next-icon {
    transform: rotate(180deg);
  }
  .carousel-item {
    height: 650px;
    .ico-arrow-next {
      width: 28px;
      height: 28px;
      display: flex;
      margin-left: 10px;
    }
    .ico-arrow-next:after {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='28' height='29' fill='none' version='1.1' viewBox='0 0 28 29' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='m27.829 13.777-8.75-8.8716c-0.2279-0.23105-0.597-0.23105-0.8249 0-0.2278 0.23105-0.2278 0.60532 0 0.83632l7.7543 7.8621h-25.425c-0.32244 0-0.58335 0.2645-0.58335 0.5914s0.26091 0.5915 0.58335 0.5915h25.425l-7.7543 7.8619c-0.2278 0.2311-0.2278 0.6054 0 0.8364 0.114 0.1155 0.2633 0.1732 0.4125 0.1732s0.2985-0.0577 0.4125-0.1732l8.75-8.8717c0.2278-0.231 0.2278-0.6052-1e-4 -0.8363z' fill='%23fff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='28' height='28.389' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      width: 28px;
      height: 28px;
    }
    img {
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
      min-height: 650px;
    }
    .carousel-caption {
      right: 20px;
      left: 20px;
      top: 100px;
      text-align: right;
      color: #ffffff;
      .title {
        font-weight: 700;
        font-size: 2.25rem;
      }
      a {
        color: #ffffff;
        transition: opacity 0.15s ease;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    &.dark {
      .ico-arrow-next:after {
        background-image: url("data:image/svg+xml,%3Csvg width='28' height='29' fill='none' version='1.1' viewBox='0 0 28 29' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='m27.829 13.777-8.75-8.8716c-0.2279-0.23105-0.597-0.23105-0.8249 0-0.2278 0.23105-0.2278 0.60532 0 0.83632l7.7543 7.8621h-25.425c-0.32244 0-0.58335 0.2645-0.58335 0.5914s0.26091 0.5915 0.58335 0.5915h25.425l-7.7543 7.8619c-0.2278 0.2311-0.2278 0.6054 0 0.8364 0.114 0.1155 0.2633 0.1732 0.4125 0.1732s0.2985-0.0577 0.4125-0.1732l8.75-8.8717c0.2278-0.231 0.2278-0.6052-1e-4 -0.8363z' fill='#{url-friendly-colour($blue-50)}'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='28' height='28.389' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      }
      .carousel-caption {
        color: $blue-50;
        a {
          color: $blue-50;
        }
      }
    }
  }

  .carousel-indicators {
    li {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      margin-left: 5px;
      opacity: 1;
      background-color: #ffffff;
      &.active {
        background-color: $blue-10;
      }
    }
  }
}
@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 1200px) {
  .header-slider {
    .carousel-item {
      .carousel-caption {
        right: 170px;
        left: 15%;
        .title {
          font-size: 3rem;
        }
      }
    }
  }
}
</style>
